
const ChallengeReward = ({rewardDetail}) => {
    const contentHtml = () => {
        return {__html: rewardDetail};
    }
    return (
        <div dangerouslySetInnerHTML={contentHtml()}></div>
    );
};

export default ChallengeReward;