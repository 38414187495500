import challengeStyles from "../../common/css/Challenge.module.css";
import myPageChallengeStyles from "./MyPageChallenge.module.css";
import commonStyles from "../../common/css/Common.module.css";
import ChangeTeamApplicationAnswerMd from "./ChangeTeamApplicationAnswerMd";
import {useState} from "react";
import {useDialog} from "../../common/hook/DialogContext";
import {Link} from "react-router-dom";
import SubmitMd from "./SubmitMd";

const MyPageChallengeContent = ({ myChallengeTeam, refresh, setRefresh }) => {
    const [changeTeamApplicationAnswerMdOpen, setChangeTeamApplicationAnswerMdOpen] = useState(false);
    const [submitMdOpen, setSubmitMdOpen] = useState(false);
    const {addAlert, addConfirm} = useDialog();

    return (
        <>
            <tr>
                <td>
                    <div>{myChallengeTeam.rowId}</div>
                </td>
                <td className={challengeStyles.ta_left}>
                    <div>
                        <div className={myPageChallengeStyles.challenge}>
                            <Link to={`/challenge/${myChallengeTeam.challengeUid}/info/overview`}>
                                <span className={`${challengeStyles.status} ${myPageChallengeStyles.status} ${myChallengeTeam.openState === "OPENED" ? `${challengeStyles.underway} ${myPageChallengeStyles.underway}` : `` }`}>
                                    [{myChallengeTeam.openState === "OPENED" ? "진행중" : myChallengeTeam.openState === "CLOSED" ? "종료" : "대기"}]
                                </span>
                                <span className={challengeStyles.name}>
                                    {` ${myChallengeTeam.name}`}
                                </span>
                            </Link>
                        </div>
                        <div className={challengeStyles.member}>
                            {myChallengeTeam.challengeTeamMemberNames.map((name, idx) => (
                                myChallengeTeam.challengeTeamMemberNames.length - 1 === idx ?
                                    name
                                    :
                                    `${name}, `
                            ))}
                        </div>
                    </div>
                </td>
                <td>
                    {myChallengeTeam.openState === "OPENED" && (
                        <button
                            type="button"
                            className={`${commonStyles.cc_btn} ${challengeStyles.cc_btn} ${myPageChallengeStyles.cc_btn} ${commonStyles.underline} ${myPageChallengeStyles.underline}`}
                            onClick={() => setSubmitMdOpen(true)}
                        >
                            <span>답안제출</span>
                        </button>
                    )}
                    {/*<div>제출완료</div>*/}
                </td>
                <td>
                    <div>{myChallengeTeam.submitCnt}</div>
                </td>
                <td>
                    <div>{myChallengeTeam.recentSubmitDate}</div>
                </td>
                <td>
                    <div>
                        {myChallengeTeam.openState === "OPENED" && (
                            <>
                                {(myChallengeTeam.isJupyter && myChallengeTeam.url && myChallengeTeam.openState !== 'CLOSED') && (
                                    <button
                                        type="button"
                                        className={`${commonStyles.cc_btn} ${challengeStyles.cc_btn} ${myPageChallengeStyles.cc_btn} ${commonStyles.underline} ${myPageChallengeStyles.underline}`}
                                        onClick={
                                            () => {
                                                addAlert(
                                                    "Jupyter 서버에 접속합니다.",
                                                    () => window.open(myChallengeTeam.url, "_blank")
                                                );
                                            }
                                        }
                                    >
                                        <span>Jupyter 접속</span>
                                    </button>
                                )}

                                {(myChallengeTeam.isLeader && myChallengeTeam.isManage) && (
                                    <button
                                        type="button"
                                        className={`${commonStyles.cc_btn} ${challengeStyles.cc_btn} ${myPageChallengeStyles.cc_btn} ${commonStyles.underline} ${myPageChallengeStyles.underline}`}
                                        onClick={() => setChangeTeamApplicationAnswerMdOpen(true)}
                                    >
                                        <span>팀 신청정보</span>
                                    </button>
                                )}
                            </>
                        )}
                    </div>
                </td>
            </tr>
            <ChangeTeamApplicationAnswerMd
                open={changeTeamApplicationAnswerMdOpen}
                closedMd={() => setChangeTeamApplicationAnswerMdOpen(false)}
                teamUid={myChallengeTeam.teamUid}
            />
            <SubmitMd
                open={submitMdOpen}
                closedMd={() => setSubmitMdOpen(false)}
                challengeName={myChallengeTeam.name}
                challengeUid={myChallengeTeam.challengeUid}
                teamUid={myChallengeTeam.teamUid}
                refresh={refresh}
                setRefresh={setRefresh}
            />
        </>
    );
};

export default MyPageChallengeContent;
