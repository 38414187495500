
const ChallengeDataFile = ({fileDetail}) => {
    const contentHtml = () => {
        return {__html: fileDetail};
    }
    return (
        <div dangerouslySetInnerHTML={contentHtml()}></div>
    );
};

export default ChallengeDataFile;