import _ from "lodash";
import {useDialog} from "../common/hook/DialogContext";
// import {openAlert} from "../component/common/MsgBox";

export const pwPattern = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*.+=-])(?=.*[0-9]).{8,200}$/;
export const emailPattern = /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;
export const phonePattern = /^01([016789])-?([0-9]{3,4})-?([0-9]{4})$/;
export const namePattern = /^[가-힣a-zA-Z ]*$/;
export const noEmojiPattern = /^[0-9가-힣a-zA-Z-]+[0-9가-힣a-zA-Z -]*$/
export const signUpIdPattern = /^[A-Za-z0-9]{5,20}$/;


export const validateEmail = (email, addAlert) => {
    // 입력 완료 후 fetch 전 체크
    if(isBlankStr(email)) {
        addAlert("이메일을 입력해주세요.");
        return false;
    } else if(!emailPattern.test(email)){
        addAlert("이메일 형식이 잘못되었습니다.");
        return false;
    }
    return true;
}

export const validatePhone = (phone, addAlert) => {
    // 입력 완료 후 fetch 전 체크
    if(isBlankStr(phone)) {
        addAlert("핸드폰번호를 입력해주세요.");
        return false;
    } else if(!phonePattern.test(phone)){
        addAlert("핸드폰번호 형식이 잘못되었습니다.\n핸드폰번호 앞자리는 010, 011, 016, 017, 018, 019만 사용 가능합니다.");
        return false;
    }
    return true;
}

export const validateName = (name, addAlert) => {
    // 입력 완료 후 fetch 전 체크
    if(isBlankStr(name)) {
        addAlert("이름을 입력해주세요.");
        return false;
    } else if(!namePattern.test(name.trim())){
        addAlert("이름 형식이 잘못되었습니다.");
        return false;
    }
    return true;
}

export const validateId = (id, addAlert) => {
    // 입력 완료 후 fetch 전 체크
    if(isBlankStr(id)) {
        addAlert("아이디을 입력해주세요.");
        return false;
    } else if(!signUpIdPattern.test(id.trim())){
        addAlert("아이디 형식이 잘못되었습니다.");
        return false;
    }
    return true;
}


export const validateEmoji = (name) => {
    if(!noEmojiPattern.test(name)){
        return false;
    }
    return true;
}

export const isBlankStr = (str) => {
    return str === null || str.trim().length === 0;
}


export const handlePhone = (str) => {
    // 전화번호 입력 시 hypen 추가
    let phone = str.replace(/[^0-9]/g, '');
    let tmp = '';

    if (_.isEqual(phone.substring(0, 2), "02")) {
        if (phone.length < 3) {
            return str;
        } else if (phone.length < 6) {
            tmp += phone.substr(0, 2);
            tmp += '-';
            tmp += phone.substr(2);
            return tmp;
        } else if (phone.length < 10) {
            tmp += phone.substr(0, 2);
            tmp += '-';
            tmp += phone.substr(2, 3);
            tmp += '-';
            tmp += phone.substr(5);
            return tmp;
        } else {
            tmp += phone.substr(0, 2);
            tmp += '-';
            tmp += phone.substr(2, 4);
            tmp += '-';
            tmp += phone.substr(6, 4);
            return tmp;
        }
    } else {
        if (phone.length < 4) {
            return str;
        } else if (phone.length < 7) {
            tmp += phone.substr(0, 3);
            tmp += '-';
            tmp += phone.substr(3);
            return tmp;
        } else if (phone.length < 11) {
            tmp += phone.substr(0, 3);
            tmp += '-';
            tmp += phone.substr(3, 3);
            tmp += '-';
            tmp += phone.substr(6);
            return tmp;
        } else {
            tmp += phone.substr(0, 3);
            tmp += '-';
            tmp += phone.substr(3, 4);
            tmp += '-';
            tmp += phone.substr(7);
            return tmp;
        }
    }
}