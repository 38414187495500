import {Link, useLocation, useNavigate} from "react-router-dom";
import Url from "../../common/Url";
import {useEffect} from "react";
import {useAuth} from "../../common/hook/AuthContext";
import {useDialog} from "../../common/hook/DialogContext";

const Header = () => {
    const { isLogin, logout, loginCheck } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const {addAlert, addConfirm} = useDialog();

    const openLogoutAlert = () => {
        addAlert(
            "로그아웃 되었습니다." ,
            () => navigate(Url.home)
        );
    };

    useEffect(() => {
        loginCheck();
    }, [location, isLogin]);

    return (
        <header id="header">
            <div>
                <h1 className="head_logo">
                    <Link to={Url.home}><span className="sr_only">홈으로 이동</span></Link>
                </h1>
                <div className="head_btn">
                    {isLogin ?
                        <Link
                            to={Url.myPageInfo}
                            className="my_info_btn"
                        >
                            <span className="sr_only">내 정보로 이동</span>
                        </Link>
                        :
                        <a
                            href={"#"}
                            className="my_info_btn"
                            onClick={
                                (e) => {
                                    e.preventDefault();
                                    addAlert("로그인이 필요합니다.");
                                }
                            }
                        >
                            <span className="sr_only">내 정보로 이동</span>
                        </a>
                    }

                    {isLogin ?
                        <a
                            href="#"
                            onClick={(e) => {
                               e.preventDefault();
                               logout(openLogoutAlert());
                            }}
                            className="login_btn logout"
                        >
                            <span>로그아웃</span>
                        </a>
                        :
                        <Link to={Url.login} className="login_btn"><span>로그인</span></Link>
                    }
                </div>
            </div>
        </header>
    );
};

export default Header;