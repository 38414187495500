const ChallengeOverview = ({overview}) => {
    const contentHtml = () => {
        return {__html: overview};
    }

    return (
        <div dangerouslySetInnerHTML={contentHtml()}></div>
    );
};

export default ChallengeOverview;