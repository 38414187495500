import {useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import _ from "lodash";
import commonStyles from "../../../common/css/Common.module.css";
import challengeStyles from "../../../common/css/Challenge.module.css";
import BbsWrap from "../BbsWrap";
import {useAuth} from "../../../common/hook/AuthContext";
import Paging from "../../common/Paging";
import { isEmptyObj } from "../../../Utils/CommonUtils";
import BoardList from "./BoardList";
import {useDialog} from "../../../common/hook/DialogContext";

const ChallengeBoardList = ({challengeUid}) => {
    
    const navigate = useNavigate();
    const location = useLocation();

    const { isLogin } = useAuth();
    const [boardList, setBoardList] = useState([]);
    const [currentPage, setCurrentPage] = useState(location.state ? location.state.prevPage : 1);
    const [totalPage, setTotalPage] = useState(1);
    const { addAlert, addConfirm } = useDialog();

    const getChallenge = () => {
        fetch(`/v1/challenge-boards?page=${currentPage - 1}&size=5&searchChallengeUid=${challengeUid}`)
            .then(async (response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.json().then(json => {
                        addAlert(
                            json.message,
                            () => navigate(`/challenge/${challengeUid}/info/overview`)
                        );
                    })
                }
            })
            .then((json) => {
                if (isEmptyObj(json))
                    return;
                setBoardList(json.data);
                setTotalPage(json.totalPages);
            }).catch(e => console.log(e.message));
        };
    
    useEffect(() => {
        getChallenge();
    }, [currentPage]);

    return (
        <>
            <BbsWrap>
                <div className={`${challengeStyles.top} ${challengeStyles.flex}`}>
                    <div className={challengeStyles.table_title}>게시판</div>
                    {isLogin ?
                            <Link to={`/challenge/${challengeUid}/board/create`}
                                    className={`${commonStyles.cc_btn} ${challengeStyles.cc_btn} ${commonStyles.bg_white_blue}`}>
                                <span>글쓰기</span>
                            </Link>
                        :
                        <a
                            href={`#`}
                            onClick={
                                (e) => {
                                    e.preventDefault();
                                    alert("로그인이 필요합니다.")
                                }
                            }
                            className={`${commonStyles.cc_btn} ${challengeStyles.cc_btn} ${commonStyles.bg_white_blue}`}
                        >
                            <span>글쓰기</span>
                        </a>
                    }
                </div>

                <BoardList boardList={boardList} prevPage={currentPage}/>

            </BbsWrap>
            {!isEmptyObj(boardList) && (
                <Paging
                    blockSize={10}
                    totalPageCount={totalPage}
                    currentPageCount={currentPage}
                    onPageClick={setCurrentPage}
                />
            )}
        </>
    )
};

export default ChallengeBoardList;