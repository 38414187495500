import challengeStyles from "../../common/css/Challenge.module.css";
import TabItem from "./TabItem";
import Url from "../../common/Url";

const ChallengeBottomTabs = ({tabMenu, innerMenu, uid}) => {
    const baseUrl = `/challenge/${uid}`;
    return (
        <>
            {tabMenu === "info" && (
                <div className={challengeStyles.bottom_tabs}>
                    <TabItem isOn={innerMenu === "overview"} url={baseUrl + `/info/overview`}><span>개요</span></TabItem>
                    <TabItem isOn={innerMenu === "rule"} url={baseUrl + `/info/rule`}><span>평가 규칙</span></TabItem>
                    <TabItem isOn={innerMenu === "schedule"} url={baseUrl + `/info/schedule`}><span>일정 상세</span></TabItem>
                    <TabItem isOn={innerMenu === "reward"} url={baseUrl + `/info/reward`}><span>상금</span></TabItem>
                </div>
            )}

            {tabMenu === "data" && (
                <div className={challengeStyles.bottom_tabs}>
                    <TabItem isOn={innerMenu === "agreement"} url={baseUrl + `/data/agreement`}><span>이용 동의서</span></TabItem>
                    <TabItem isOn={innerMenu === "explanation"} url={baseUrl + `/data/explanation`}><span>데이터 설명</span></TabItem>
                    <TabItem isOn={innerMenu === "file"} url={baseUrl + `/data/file`}><span>파일</span></TabItem>
                </div>
            )}

            {tabMenu === "ranking" && (
                <div className={challengeStyles.bottom_tabs}>
                    <TabItem isOn={innerMenu === "public"} url={baseUrl + `/public-ranking`}><span>공개순위</span></TabItem>
                    <TabItem isOn={innerMenu === "final"} url={baseUrl + `/final-ranking`}><span>최종순위</span></TabItem>
                    <TabItem isOn={innerMenu === "chart"} url={baseUrl + `/chart`}><span>TOP20 CHART</span></TabItem>
                </div>
            )}
        </>
    );
};

export default ChallengeBottomTabs;