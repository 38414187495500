import {useEffect, useState} from "react";
import {MdBody, MdFooter, MdHeader, ModalBox} from "../common/ModalTags";
import commonStyles from "../../common/css/Common.module.css";
import challengeStyles from "../../common/css/Challenge.module.css";
import EnterItem from "../common/EnterItem";
import RadioItem from "../common/RadioItem";
import Enter from "../common/Enter";
import {ApplicationMdConsent, ApplicationMdInput, ApplicationMdSelection} from "./ChangeTeamApplicationItem";
import useKeepDoubleClick from "../../common/hook/useKeepDoubleClick";
import {useDialog} from "../../common/hook/DialogContext";

const ChangeTeamApplicationAnswerMd = ({open, closedMd, teamUid}) => {
    const [teamInfos, setTeamInfos] = useState([]);
    const [name, setName] = useState('');
    const [intro, setIntro] = useState('');
    const [teamCondition, setTeamCondition] = useState('');
    const [teamApplicationInfos, setTeamApplicationInfos] = useState([]);
    let applicationRequests = [];
    const [clicked, preventDoubleClick] = useKeepDoubleClick();
    const {addAlert, addConfirm} = useDialog();
    const conditionValues = [
        "모두", "임상의만", "개발자만", "임상의+개발자"
    ];

    const close = () => {
        closedMd();
    }

    const conditionHandle = (e) => {
        setTeamCondition(e.target.value);
        if(e.target.value === teamCondition) {
            e.target.checked = true;
        }
    };

    const getTeamInfos = () => {
        fetch(`/v1/challenge-team-application/team/${teamUid}`)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.json().then(json => {
                        addAlert(json.message);
                    })
                }
            })
            .then((json) => {
                setTeamInfos(json);
                setName(json.name);
                setIntro(json.intro);
                setTeamCondition(json.teamCondition);
                if (json.teamApplicationInfos) {
                   json.teamApplicationInfos.map((info, idx) => (
                        Number(info.itemValueUid) === 0 ?
                            info.itemValueUid =`withoutAnswer${idx}`
                            :
                            ""
                    ));
                }
                setTeamApplicationInfos(json.teamApplicationInfos);
            });
    }
    useEffect(() => {
        if (open) {
            getTeamInfos();
        }
    }, [open])

    const updateData = () => {
        addConfirm(
            "팀 신청정보를 변경하시겠습니까?",
            () => {
                fetch(`/v1/challenge-team/my-challenge-team/${teamUid}`, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    method: "put",
                    credentials: "include",
                    redirect: "follow",
                    body: JSON.stringify({
                        name: name,
                        teamCondition: teamCondition,
                        intro: intro,
                        applicationRequests: applicationRequests

                    }),
                }).then((response) => {
                    clicked.current = false;
                    if (response.ok) {
                        addAlert("변경 되었습니다.");
                        close();
                    } else if (response.status === 400 || response.status === 409) {
                        response.json().then(json => {
                            addAlert(json.message);
                        })
                    } else if (response.status === 401) {
                        response.json().then(json => {
                            addAlert(json.message);
                        })
                    }else {
                        throw new Error("변경할 수 없습니다. 다시 시도해주세요.");
                    }
                }).catch(e => {
                    addAlert(e.message);
                });
            },
            () => {
                clicked.current = false;
            }
        )
    };

    const validation = () => {
        if (name.trim() === "") {
            addAlert("팀명을 입력해주세요.");
            return;
        }

        if (intro.trim() === "") {
            addAlert("팀 소개를 입력해주세요.");
            return;
        }

        for (let i = 0; i < applicationRequests.length; i++) {
            if (typeof applicationRequests[i].answer === "string") {
                if (applicationRequests[i].answer.trim() === "") {
                    addAlert("입력 하지 않은 신청정보가 존재합니다.");
                    return;
                }
            } else if (typeof applicationRequests[i].answer === "boolean") {
                if (!applicationRequests[i].answer) {
                    addAlert("동의 하지 않은 신청정보가 존재합니다.");
                    return;
                }
            } else {
                if (!applicationRequests[i].answer || applicationRequests[i].answer === 0) {
                    addAlert("선택 하지 않은 신청정보가 존재합니다.");
                    return;
                }
            }
        }

        return true;
    };
    const submit = () => {
        applicationRequests =
            teamApplicationInfos.map(teamApplicationInfo => (
                {
                    itemValueUid: String(teamApplicationInfo.itemValueUid).startsWith("withoutAnswer") ? 0 : teamApplicationInfo.itemValueUid ,
                    answer: teamApplicationInfo.answer,
                    itemUid: teamApplicationInfo.itemUid
                }
            ));
        preventDoubleClick(updateData, validation);
    };


    const applicationMdInputChange = (e, itemValueUid) => {
        setTeamApplicationInfos(teamApplicationInfos.map(teamApplicationInfo => (
            teamApplicationInfo.itemValueUid === itemValueUid ? {...teamApplicationInfo, answer: e.target.value} : teamApplicationInfo
        )));
    };

    const applicationMdRadioHandle = (e, itemValueUid) => {
        setTeamApplicationInfos(teamApplicationInfos.map(teamApplicationInfo => (
            teamApplicationInfo.itemValueUid === itemValueUid ? {...teamApplicationInfo, answer: e.target.value} : teamApplicationInfo
        )));
        const targetInfo = teamApplicationInfos.filter(info => info.itemValueUid === itemValueUid);

        if(e.target.value == targetInfo.answer) {
            e.target.checked = true;
        }
    };

    return (
        <ModalBox addClass={commonStyles.team_info} open={open} close={close}>
            <div>
                <div>
                    <MdHeader>{teamInfos.name} - 팀 신청정보</MdHeader>
                    <MdBody>
                        <div className={`${commonStyles.team_enter_box} ${challengeStyles.team_enter_box}`}>
                            <div className={`${commonStyles.enter_wrap} ${challengeStyles.enter_wrap}`}>
                                <EnterItem addClass={challengeStyles.enter_item}>
                                    <label htmlFor="team_name" className={challengeStyles.item_title}>팀명을 입력해 주세요.</label>
                                    <Enter
                                        id={"team_name"}
                                        type={"text"}
                                        addClass={challengeStyles.enter}
                                        value={name}
                                        inputChange={(e) => setName(e.target.value)}
                                        maxLength={60}
                                    />
                                </EnterItem>
                                <EnterItem addClass={challengeStyles.enter_item}>
                                    <label htmlFor="team_intro" className={challengeStyles.item_title}>팀 소개를 입력해 주세요.</label>
                                    <Enter
                                        id={"team_intro"}
                                        type={"text"}
                                        addClass={challengeStyles.enter}
                                        value={intro}
                                        inputChange={(e) => setIntro(e.target.value)}
                                        maxLength={60}
                                    />
                                </EnterItem>
                                <EnterItem addClass={challengeStyles.enter_item}>
                                    <div className={challengeStyles.item_title}>팀원 모집 조건을 선택해 주세요.</div>
                                    <div className={`${commonStyles.enter} ${challengeStyles.enter}`}>
                                        <div className={challengeStyles.option_list}>
                                            {conditionValues.map((conditionValue, idx) => (
                                                <RadioItem key={`conditionValue${idx}`} addClass={challengeStyles.radio_item}>
                                                    <label>
                                                        <input
                                                            id={`team_option`}
                                                            name={`team_option`}
                                                            type="radio"
                                                            value={conditionValue}
                                                            onClick={(e) => conditionHandle(e)}
                                                            checked={conditionValue === teamCondition}
                                                        />
                                                        <span>{conditionValue}</span>
                                                    </label>
                                                </RadioItem>
                                            ))}
                                        </div>
                                    </div>
                                </EnterItem>


                                {teamApplicationInfos.map((teamApplicationInfo, idx) => (
                                    <>
                                        {teamApplicationInfo.itemType === "INPUT" && (
                                            <ApplicationMdInput
                                                key={Number(teamApplicationInfo.itemValueUid) === 0 ? `withoutAnswer${idx}` : teamApplicationInfo.itemValueUid}
                                                teamApplicationInfo={teamApplicationInfo}
                                                applicationMdInputChange={applicationMdInputChange}
                                            />
                                        )}

                                        {teamApplicationInfo.itemType === "SELECTION" && (
                                            <ApplicationMdSelection
                                                key={Number(teamApplicationInfo.itemValueUid) === 0 ? `withoutAnswer${idx}` : teamApplicationInfo.itemValueUid}
                                                teamApplicationInfo={teamApplicationInfo}
                                                applicationMdRadioHandle={applicationMdRadioHandle}
                                            />
                                        )}

                                        {teamApplicationInfo.itemType === "CONSENT" && (
                                            <ApplicationMdConsent
                                                key={Number(teamApplicationInfo.itemValueUid) === 0 ? `withoutAnswer${idx}` : teamApplicationInfo.itemValueUid}
                                                teamApplicationInfo={teamApplicationInfo}
                                                teamApplicationInfos={teamApplicationInfos}
                                                setTeamApplicationInfos={setTeamApplicationInfos}
                                                open={open}
                                            />
                                        )}
                                    </>
                                ))}
                            </div>
                        </div>
                    </MdBody>
                    <MdFooter>
                        <div className={`${commonStyles.bottom_btn_wrap} ${challengeStyles.bottom_btn_wrap}`}>
                            <button
                                type="button"
                                className={`${commonStyles.cc_btn} ${challengeStyles.cc_btn} ${commonStyles.bg_gray}`}
                                onClick={submit}
                            >
                                <span>변경</span>
                            </button>
                        </div>
                    </MdFooter>
                </div>
            </div>
        </ModalBox>
    );
};

export default ChangeTeamApplicationAnswerMd;