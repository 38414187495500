import SrOnly from "../../component/common/SrOnly";
import AccountWrap from "../../component/account/AccountWrap";
import AccountBox from "../../component/account/AccountBox";
import AccountTitle from "../../component/account/AccountTitle";
import accountStyles from "../../common/css/Account.module.css";
import Enter from "../../component/common/Enter";
import commonStyles from "../../common/css/Common.module.css";
import loginStyles from "./Login.module.css";
import EnterItem from "../../component/common/EnterItem";
import CheckItem from "../../component/common/CheckItem";
import AccountBtnWrap from "../../component/account/AccountBtnWrap";
import {Link, useNavigate} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import Url from "../../common/Url";
import Container from "../../component/common/Container";
import Content from "../../component/common/Content";
import useKeepDoubleClick from "../../common/hook/useKeepDoubleClick";
import {useAuth} from "../../common/hook/AuthContext";
import {useDialog} from "../../common/hook/DialogContext";

const Login = () => {
    const { isLogin, login, logout } = useAuth();
    const [id, setId] = useState("");
    const [pw, setPw] = useState("");

    const navigate = useNavigate();
    const selectRef = useRef();
    const [clicked, preventDoubleClick] = useKeepDoubleClick();
    const {addAlert, addConfirm} = useDialog();

    const onChangeId = (e) => {
        setId(e.target.value);
    }

    const onChangePw = (e) => {
        setPw(e.target.value);
    }

    const validate = () => {
        if (!id) {
            addAlert("아이디가 입력되지 않았습니다.\n로그인 정보를 확인하고 다시 시도해주세요");
            return false;
        } else if (!pw) {
            addAlert("비밀번호가 입력되지 않았습니다.\n로그인 정보를 확인하고 다시 시도해주세요");
            return false;
        }
        return true;
    }

    useEffect(() => {
        if (localStorage.getItem("rememberedId") !== null) {
            setId(localStorage.getItem("rememberedId"));
            selectRef.current.checked = true;
        }
    }, []);

    useEffect(() => {
        if (isLogin) {
            addAlert(
                "이미 로그인 상태입니다.\n메인 페이지로 이동합니다.",
                () => navigate(Url.home, { replace: true })
            );
        }
    }, [isLogin]);

    return (
        <Container>
            <Content>

                <SrOnly>로그인 페이지</SrOnly>

                <AccountWrap>
                    <AccountBox>
                        <AccountTitle>로그인</AccountTitle>
                        <div className="enter_wrap">
                            <EnterItem addClass={accountStyles.enter_item}>
                                <Enter
                                    addClass={`${accountStyles.enter}`}
                                    id={"user_id"}
                                    type={"text"}
                                    title={"아이디 입력"}
                                    placeholder={"아이디 입력"}
                                    label={"아이디"}
                                    inputChange={onChangeId}
                                    value={id}
                                >
                                </Enter>
                            </EnterItem>
                            <EnterItem addClass={accountStyles.enter_item}>
                                <Enter
                                    addClass={accountStyles.enter}
                                    id={"user_pw"}
                                    type={"password"}
                                    title={"비밀번호 입력"}
                                    placeholder={"비밀번호 입력"}
                                    label={"비밀번호 입력"}
                                    inputChange={onChangePw}
                                    name={"password"}
                                    value={pw}
                                />
                            </EnterItem>
                            <EnterItem addClass={`${accountStyles.enter_item} ${accountStyles.option}`}>
                                <div className={`${commonStyles.enter} ${accountStyles.enter}`}>
                                    <CheckItem>
                                        <label>
                                            <input type="checkbox" ref={selectRef} />
                                                <span>아이디 저장</span>
                                        </label>
                                    </CheckItem>
                                </div>
                                <Link to={Url.findId} className={accountStyles.find_account_btn}><span>아이디 찾기</span></Link>
                                <Link to={Url.passwordFind} className={accountStyles.find_account_btn}><span>비밀번호 찾기</span></Link>
                            </EnterItem>
                        </div>

                        <AccountBtnWrap addClass={loginStyles.account_btn_wrap}>
                            <a href="#" onClick={(e) => {e.preventDefault(); login(validate, id, pw, selectRef)}} className={`${commonStyles.cc_btn} ${accountStyles.cc_btn}`} title="로그인으로 이동"><span>로그인</span></a>
                            <Link to={Url.signUp} className={`${commonStyles.cc_btn} ${accountStyles.cc_btn} ${commonStyles.bg_white}`} title="회원가입으로 이동">
                                <span>회원가입</span>
                            </Link>
                        </AccountBtnWrap>
                    </AccountBox>
                </AccountWrap>

            </Content>
        </Container>
    );
};

export default Login;