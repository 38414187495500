import commonStyles from "../../common/css/Common.module.css";
import {useDialog} from "../../common/hook/DialogContext";
const CustomAlert = () => {
    const {alerts, removeAlert} = useDialog();

    return (
        alerts.map(alert => (
            <div className={commonStyles.dialog_wrap}>
                <div>
                    <div className={commonStyles.middle}>
                        <span style={{whiteSpace: "pre-wrap"}}>{alert.message}</span>
                    </div>
                    <div className={commonStyles.bottom}>
                        <div className={commonStyles.btns}>
                            <a
                                href="#"
                                className={commonStyles.cc_btn}
                                onClick={
                                    (e) => {
                                        e.preventDefault();
                                        removeAlert(alert.id);
                                        alert.afterClose();
                                    }
                                }
                            >
                                <span>확인</span>
                            </a>
                        </div>
                    </div>
                    <button
                        type="button"
                        className={commonStyles.md_close_btn}
                        onClick={
                            () => {
                                removeAlert(alert.id);
                                alert.afterClose();
                            }
                        }
                    >
                        <span className={commonStyles.sr_only}>팝업 닫기</span>
                    </button>
                </div>
            </div>
        ))
    );
};

export default CustomAlert;