import {useEffect, useState} from "react";
import Plot from 'react-plotly.js';
import _ from "lodash";
import {useDialog} from "../../common/hook/DialogContext";
import {useNavigate} from "react-router-dom";

const ChallengeChart = ({uid}) => {
    const [rankingChartData, setRankingChartData] = useState([]);
    const {addAlert} = useDialog();
    const navigate = useNavigate();

    const getTop20ChartData = () => {
        fetch(`/v1/challenge/${uid}/ranking-chart`)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.json().then(json => {
                        addAlert(json.message, () => navigate(`/challenge/${uid}/public-ranking`));
                    })
                }
            })
            .then((json) => {
                if (json === undefined) return;
                json.chartData.map(data => (
                    setRankingChartData(
                        prevState =>
                            [
                                ...prevState,
                                {
                                    x: json.x,
                                    y: data.y,
                                    mode: 'lines',
                                    name: data.teamName
                                }
                            ]

                    )
                ));
            });
    }

    useEffect(() => {
        getTop20ChartData();
    }, []);

    return (
        <Plot
            data={rankingChartData}
            layout={{
                width: 1000,
                height: 1000,
                title: 'TOP20 chart',
                hovermode: 'x',
                yaxis: {
                    title: '순위',
                    dtick: 1, // y축 간격을 1로 설정
                    range: [20, 0],
                    zeroline: false
                },
                xaxis: {
                    title: '날짜',
                    dtick: 86400000, // 하루 간격 (밀리초 단위)
                    range: rankingChartData.x ? [rankingChartData.x[0], rankingChartData.x[rankingChartData.x.length - 1]] : []
                },
            }}
        />
    );
};

export default ChallengeChart;