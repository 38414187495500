import commonStyles from "../../common/css/Common.module.css";
import {MdBody, MdFooter, MdHeader, ModalBox} from "../common/ModalTags";
import challengeStyles from "../../common/css/Challenge.module.css";
import EnterItem from "../common/EnterItem";
import RadioItem from "../common/RadioItem";
import {useEffect, useState} from "react";
import _ from "lodash";
import useKeepDoubleClick from "../../common/hook/useKeepDoubleClick";
import {useDialog} from "../../common/hook/DialogContext";

const ChangeTeamLeaderMd = ({open, closedMd, memberInfos, teamInfoRefresh, setTeamInfoRefresh}) => {
    const [selectedTeamApplyUid, setSelectedTeamApplyUid] = useState(0);
    const [clicked, preventDoubleClick] = useKeepDoubleClick();
    const {addAlert, addConfirm} = useDialog();

    const close = () => {
        setSelectedTeamApplyUid(0);
        closedMd();
    };

    const teamApplyUidHandle = (e) => {
        setSelectedTeamApplyUid(e.target.value);
        if(e.target.value == selectedTeamApplyUid) {
            e.target.checked = true;
        }
    };

    const updateData = () => {
        addConfirm(
            "팀장을 변경하시겠습니까?",
            () => {
                fetch(`/v1/challenge-team-apply/${selectedTeamApplyUid}/leader`, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    method: "put",
                    credentials: "include",
                    redirect: "follow",
                }).then((response) => {
                    clicked.current = false;
                    if (response.ok) {
                        addAlert(
                            "변경 되었습니다.",
                            () => {
                                setTeamInfoRefresh(!teamInfoRefresh);
                                close();
                            }
                        );
                    } else if (response.status === 400) {
                        response.json().then(json => {
                            addAlert(json.message);
                        })
                    } else if (response.status === 401) {
                        response.json().then(json => {
                            addAlert(json.message);
                        })
                    }else {
                        throw new Error("변경할 수 없습니다. 다시 시도해주세요.");
                    }
                }).catch(e => {
                    addAlert(e.message);
                });
            },
            () => clicked.current = false
        )

    };

    const validation = () => {
        if (selectedTeamApplyUid === 0) {
            addAlert("팀장으로 변경할 멤버를 선택해주세요.");
            return;
        }

        return true;
    };
    const submit = () => {
        preventDoubleClick(updateData, validation);
    };


    return (
        <ModalBox addClass={commonStyles.change_leader} open={open} close={close}>
            <div>
                <div>
                    <MdHeader>팀장 변경</MdHeader>
                    <MdBody>
                        <div>
                            <div>
                                <div className={`${commonStyles.enter_wrap} ${challengeStyles.enter_wrap}`}>
                                    <EnterItem>
                                        <div className={`${commonStyles.enter} ${challengeStyles.enter} ${commonStyles.flex} ${challengeStyles.flex}`}>
                                            {!_.isEmpty(memberInfos) ?
                                                memberInfos.map((memberInfo, idx) => (
                                                    <RadioItem key={`memberInfo${idx}`} addClass={challengeStyles.radio_item}>
                                                        <label>
                                                            <input
                                                                type="radio"
                                                                id={`team_leader_${idx + 1}`}
                                                                name="team_leader"
                                                                value={memberInfo.teamApplyUid}
                                                                onClick={(e) => teamApplyUidHandle(e)}
                                                                checked={memberInfo.teamApplyUid == selectedTeamApplyUid}
                                                            />
                                                            <span>{memberInfo.name}</span>
                                                        </label>
                                                    </RadioItem>
                                                ))
                                                :
                                                ""
                                            }


                                        </div>
                                    </EnterItem>
                                </div>
                            </div>
                        </div>
                    </MdBody>
                    <MdFooter>
                        <div className={`${commonStyles.bottom_btn_wrap} ${challengeStyles.bottom_btn_wrap}`}>
                            <button
                                type="button"
                                className={`${commonStyles.cc_btn} ${challengeStyles.cc_btn}`}
                                onClick={submit}
                            >
                                <span>변경</span>
                            </button>
                        </div>
                    </MdFooter>
                </div>
            </div>
        </ModalBox>
    );
};

export default ChangeTeamLeaderMd;