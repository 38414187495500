
const ChallengeSchedule = ({scheduleDetail}) => {
    const contentHtml = () => {
        return {__html: scheduleDetail};
    }
    return (
        <div dangerouslySetInnerHTML={contentHtml()}></div>
    );
};

export default ChallengeSchedule;