
const ChallengeDataExplanation = ({explanation}) => {
    const contentHtml = () => {
        return {__html: explanation};
    }
    return (
        <div dangerouslySetInnerHTML={contentHtml()}></div>
    );
};

export default ChallengeDataExplanation;