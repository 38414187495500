import "../common/css/main.css";
import iconChallenge from "../common/img/icon-challenge.png";
import iconTeam from "../common/img/icon-team.png";
import {useEffect, useState} from "react";
import ChallengeList from "../component/main/ChallengeList";
import Paging from "../component/common/Paging";
import {useDialog} from "../common/hook/DialogContext";


const Main = () => {
    const [challenges, setChallenges] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [inProgressCnt, setInProgressCnt] = useState(0);
    const [participationTeamCnt, setParticipationTeamCnt] = useState(0);
    const {addAlert, addConfirm} = useDialog();

    const text = `

    

    MAIC 홈페이지 리뉴얼 중입니다.

    
    
    `;

    const getChallenges = () => {
        fetch(`/v1/challenge?page=${currentPage - 1}&size=6`)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.json().then(json => {
                        addAlert(json.message);
                    })
                }
            })
            .then((json) => {
                if (json === undefined) return;
                setChallenges(json.pageInfos.data);
                setTotalPage(json.pageInfos.totalPages);
                setInProgressCnt(json.inProgressInfo.inProgressCnt);
                setParticipationTeamCnt(json.inProgressInfo.participationTeamCnt);
            });
            //addAlert(text);
    }

    useEffect(() => {
        getChallenges();
    },[currentPage]);

    return (
        <main id="container">
            <div id="content">

                <h2 className="sr_only">메인 페이지</h2>

                <section className="visual_sec">
                    <div>
                        <h2>MAIC</h2>
                        <h3><em>M</em>edical <em>AI C</em>hallenge</h3>
                        <p>SNUH Medical AI Challenge(MAIC)는 서울대학교병원의 의료 데이터를 활용한</p>
                        <p>AI 연구개발 활성화를 위해 만들어진 의료인공지능 경진대회 플랫폼입니다.</p>
                    </div>
                </section>

                <section className="content_sec">
                    <div className="challenge_wrap">
                        <div className="challenge_info">
                            <div className="info_item">
                                <div className="info_image">
                                    <img src={iconChallenge} alt="" />
                                </div>
                                <div className="info_name">진행 중인 대회</div>
                                <div className="info_data"><em>{inProgressCnt}</em>개</div>
                            </div>
                            <div className="info_item">
                                <div className="info_image">
                                    <img src={iconTeam} alt="" />
                                </div>
                                <div className="info_name">참여 팀</div>
                                <div className="info_data"><em>{participationTeamCnt}</em>개</div>
                            </div>
                        </div>
                        <ChallengeList challenges={challenges} />
                    </div>

                    <Paging
                        blockSize={10}
                        totalPageCount={totalPage}
                        currentPageCount={currentPage}
                        onPageClick={setCurrentPage}
                    />
                </section>

            </div>
        </main>
    );
};

export default Main;