import Container from "../../component/common/Container";
import Content from "../../component/common/Content";
import SrOnly from "../../component/common/SrOnly";
import AccountWrap from "../../component/account/AccountWrap";
import AccountBox from "../../component/account/AccountBox";
import AccountTitle from "../../component/account/AccountTitle";
import ConsentWrap from "../../component/account/ConsentWrap";
import ConsentItem from "../../component/account/ConsentItem";
import ConsentBox from "../../component/account/ConsentBox";
import EnterItem from "../../component/common/EnterItem";
import accountStyles from "../../common/css/Account.module.css";
import Enter from "../../component/common/Enter";
import CheckItem from "../../component/common/CheckItem";
import Essential from "../../component/common/Essential";
import commonStyles from "../../common/css/Common.module.css";
import CcBtn from "../../component/common/CcBtn";
import RadioItem from "../../component/common/RadioItem";
import AccountBtnWrap from "../../component/account/AccountBtnWrap";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {FormInputHelper} from "../../common/php_lib/form-input-helper";
import {emailPattern, isBlankStr, namePattern, pwPattern, signUpIdPattern} from "../../Utils/ValidateUtils";
import useKeepDoubleClick from "../../common/hook/useKeepDoubleClick";
import {useDialog} from "../../common/hook/DialogContext";

const SignUp = () => {
    const [signUpData, setSignUpData] = useState({
        userId: '',
        password: '',
        checkPassword: '',
        userName: '',
        email: '',
        dept: '',
        position: '',
        isClinician: ''
    });
    const [duplicateId, setDuplicateId] = useState(false);
    const [tosAgreement, setTosAgreement] = useState(false);
    const [privacyAgreement, setPrivacyAgreement] = useState(false);
    const navigate = useNavigate();
    const [clicked, preventDoubleClick] = useKeepDoubleClick();
    const {addAlert, addConfirm} = useDialog();

    const idChange = (event) => {
        setSignUpData(prevState => ({
            ...prevState,
            ['userId']: event.target.value
        }));
        setDuplicateId(true);
    };

    const inputChange = (event) => {
        const { name, value } = event.target;
        setSignUpData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const isClinicianChange = (event) => {
        setSignUpData(prevState => ({
            ...prevState,
            ['isClinician']: event.target.value
        }));
    };

    const checkIdValidate = () => {
        if(!signUpIdPattern.test(signUpData.userId) || !signUpData.userId) {
            addAlert("아이디를 올바른 형식으로 입력해 주세요.");
            return false;
        }
        return true;
    }

    const checkId = () => {
        fetch("/v1/users/check-duplicate-id", {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
                userId: signUpData.userId,
            }),
        }).then((response) => {
            clicked.current = false;
            if (response.ok) {
                setDuplicateId(false);
                addAlert("사용 가능한 아이디 입니다.");
            } else if (response.status === 409) {
                setDuplicateId(true);
                addAlert("이미 사용중인 아이디 입니다.");
            }
        });
    };

    const signUpEvent = () => {
        addConfirm(
            "회원가입 신청을 하시겠습니까?",
            () => {
                fetch("/v1/users/signup", {
                    method: "post",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    credentials: "include",
                    body: JSON.stringify(signUpData),
                }).then((response) => {
                    clicked.current = false;
                    if(response.status === 201) {
                        addAlert(
                            "회원가입 되었습니다.",
                            () => navigate("/", { state: { id: signUpData.userId } })
                        );
                    } else {
                        return response.json();
                    }
                }).then(json => {
                    if(json !== undefined) {
                        addAlert(json.message);
                    }
                });
            },
            () => clicked.current = false
        )

    };

    const tosCheckFromChildren = () => {
        !tosAgreement ? setTosAgreement(true) : setTosAgreement(false);
    };

    const privacyCheckFromChildren = () => {
        !privacyAgreement ? setPrivacyAgreement(true) : setPrivacyAgreement(false);
    };

    const validate = () => {
        if (!tosAgreement) {
            addAlert("이용약관에 동의해주세요.")
            return false;
        }

        if (!privacyAgreement) {
            addAlert("개인정보 처리방침에 동의해주세요.")
            return false;
        }

        if(!signUpIdPattern.test(signUpData.userId) || !signUpData.userId) {
            addAlert(`아이디를 형식에 맞게 입력해 주세요.\n(5자 이상 20자 이하)`);
            return false;
        }

        if (duplicateId) {
            addAlert("아이디 중복 확인을 해주세요.")
            return false;
        }
        if(!pwPattern.test(signUpData.password) || !signUpData.password) {
            addAlert("비밀번호를 영문, 숫자, 특수 문자를 사용하여\n8자 이상으로 입력해 주세요.");
            return false;
        }
        if(isBlankStr(signUpData.checkPassword)) {
            addAlert("비밀번호 확인을 입력해주세요.");
            return false;
        }
        if (signUpData.password !== signUpData.checkPassword) {
            addAlert("비밀번호가 일치하지 않습니다.");
            return false;
        }

        if (!namePattern.test(signUpData.userName) || !signUpData.userName) {
            addAlert("이름을 올바르게 입력해주세요");
            return false;
        }
        if (!emailPattern.test(signUpData.email) || !signUpData.email) {
            addAlert("이메일을 올바르게 입력해주세요");
            return false;
        }
        return true;
    }

    useEffect(() => {
        FormInputHelper.initKeyUpChecker();
        FormInputHelper.initKeyDownChecker();
        FormInputHelper.initCharacterLimiter();
    }, []);

    return (
        <Container>
            <Content>

                <SrOnly tag={"h2"}>회원가입 페이지</SrOnly>

                <AccountWrap>
                    <AccountBox>
                        <AccountTitle>회원 가입</AccountTitle>
                        <ConsentWrap>
                            <ConsentItem>
                                <ConsentBox tabIndex={1}>
                                    제 1장. 총칙 제1조 목적 이 약관은 서울대학교병원(이하 "병원"이라 한다)에서 운영하는 인터넷 홈페이지의 서비스 (이하 "서비스"라 한다)를이용함에
                                    있어 사이트와 이용자의 권리 의무 및 책임사항을 규정함을 목적으로 합니다. 제2조 용어정의 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
                                </ConsentBox>
                                <div className="enter_wrap">
                                    <EnterItem addClass={accountStyles.enter_item}>
                                        <div className={`${commonStyles.enter} ${accountStyles.enter}`}>
                                            <CheckItem>
                                                <label>
                                                    <input type="checkbox" onChange={tosCheckFromChildren} checked={tosAgreement} />
                                                    <span><Essential>[필수]</Essential>이용약관에 동의합니다.</span>
                                                </label>
                                            </CheckItem>
                                        </div>
                                    </EnterItem>
                                </div>
                            </ConsentItem>
                            <ConsentItem>
                                <ConsentBox tabIndex={1}>
                                    제 1장. 총칙 제1조 목적 이 약관은 서울대학교병원(이하 "병원"이라 한다)에서 운영하는 인터넷 홈페이지의 서비스 (이하 "서비스"라 한다)를이용함에
                                    있어 사이트와 이용자의 권리 의무 및 책임사항을 규정함을 목적으로 합니다. 제2조 용어정의 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
                                </ConsentBox>
                                <div className="enter_wrap">
                                    <EnterItem addClass={accountStyles.enter_item}>
                                        <div className={`${commonStyles.enter} ${accountStyles.enter}`}>
                                            <CheckItem>
                                                <label>
                                                    <input type="checkbox" onChange={privacyCheckFromChildren} checked={privacyAgreement} />
                                                    <span><Essential>[필수]</Essential>개인정보 처리방침에 동의합니다.</span>
                                                </label>
                                            </CheckItem>
                                        </div>
                                    </EnterItem>
                                </div>
                            </ConsentItem>
                        </ConsentWrap>
                        <div className="enter_wrap">
                            <EnterItem addClass={accountStyles.enter_item}>
                                <Enter
                                    addClass={`${accountStyles.enter} ${commonStyles.flex} ${accountStyles.flex}`}
                                    id={"user_id"}
                                    type={"text"}
                                    title={"아이디 입력"}
                                    placeholder={"아이디 입력"}
                                    label={"아이디"}
                                    inputChange={idChange}
                                    value={signUpData.userId}
                                >
                                    <CcBtn
                                        addClass={`${accountStyles.cc_btn} ${commonStyles.bg_gray}`}
                                        title={"아이디 중복 확인"}
                                        onClick={() => preventDoubleClick(checkId, checkIdValidate)}
                                    >
                                        <span>중복 확인</span>
                                    </CcBtn>
                                </Enter>
                            </EnterItem>
                            <EnterItem addClass={accountStyles.enter_item}>
                                <Enter
                                    addClass={accountStyles.enter}
                                    id={"user_pw"}
                                    type={"password"}
                                    title={"비밀번호 입력 (영문+숫자+특수문자 조합 8자 이상)"}
                                    placeholder={"비밀번호 입력 (영문+숫자+특수문자 조합 8자 이상)"}
                                    label={"비밀번호"}
                                    inputChange={inputChange}
                                    name={"password"}
                                    value={signUpData.password}
                                />
                            </EnterItem>
                            <EnterItem addClass={accountStyles.enter_item}>
                                <Enter
                                    addClass={accountStyles.enter}
                                    id={"user_cpw"}
                                    type={"password"}
                                    title={"비밀번호 확인 입력"}
                                    placeholder={"비밀번호 확인 입력"}
                                    label={"비밀번호 확인"}
                                    inputChange={inputChange}
                                    name={"checkPassword"}
                                    value={signUpData.checkPassword}
                                />
                            </EnterItem>
                            <EnterItem addClass={accountStyles.enter_item}>
                                <Enter
                                    addClass={accountStyles.enter}
                                    id={"user_name"}
                                    type={"text"}
                                    title={"이름 입력"}
                                    placeholder={"이름 입력"}
                                    label={"이름 입력"}
                                    inputChange={inputChange}
                                    name={"userName"}
                                    value={signUpData.userName}
                                    maxLength={30}
                                    dataType={"user_name"}
                                />
                            </EnterItem>
                            <EnterItem addClass={accountStyles.enter_item}>
                                <Enter
                                    addClass={accountStyles.enter}
                                    id={"user_email"}
                                    type={"text"}
                                    title={"이메일 입력"}
                                    placeholder={"이메일 입력"}
                                    label={"이메일 입력"}
                                    inputChange={inputChange}
                                    name={"email"}
                                    value={signUpData.email}
                                    maxLength={50}
                                    dataType={"email_address"}
                                />
                            </EnterItem>
                            <EnterItem addClass={accountStyles.enter_item}>
                                <div className={`${commonStyles.enter} ${accountStyles.enter} ${commonStyles.flex} ${accountStyles.flex}`}>
                                    <RadioItem addClass={accountStyles.radio_item}>
                                        <label>
                                            <input type="radio" name="account_radio" onChange={isClinicianChange} value={"true"} checked={signUpData.isClinician === "true"} />
                                            <span>임상의</span>
                                        </label>
                                    </RadioItem>
                                    <RadioItem addClass={accountStyles.radio_item}>
                                        <label>
                                            <input type="radio" name="account_radio" onChange={isClinicianChange} value={"false"} checked={signUpData.isClinician === "false"} />
                                            <span>임상의 아님</span>
                                        </label>
                                    </RadioItem>
                                </div>
                            </EnterItem>
                            <EnterItem addClass={accountStyles.enter_item}>
                                <Enter
                                    addClass={accountStyles.enter}
                                    id={"user_team"}
                                    type={"text"}
                                    title={"[선택] 소속병원/기관 입력"}
                                    placeholder={"[선택] 소속병원/기관 입력"}
                                    label={"[선택] 소속병원/기관 입력"}
                                    inputChange={inputChange}
                                    name={"dept"}
                                    value={signUpData.dept}
                                    maxLength={50}
                                />
                            </EnterItem>
                            <EnterItem addClass={accountStyles.enter_item}>
                                <Enter
                                    addClass={accountStyles.enter}
                                    id={"user_position"}
                                    type={"text"}
                                    title={"[선택] 직위 입력"}
                                    placeholder={"[선택] 직위 입력"}
                                    label={"[선택] 직위 입력"}
                                    inputChange={inputChange}
                                    name={"position"}
                                    value={signUpData.position}
                                    maxLength={50}
                                />
                            </EnterItem>
                        </div>

                        <AccountBtnWrap>
                            <a  href={"#"}
                                onClick={(event) => {
                                    event.preventDefault();
                                    preventDoubleClick(signUpEvent, validate);
                                }}
                                className={`${commonStyles.cc_btn} ${accountStyles.cc_btn}`}
                                title="로그인으로 이동">
                                <span>가입하기</span>
                            </a>
                        </AccountBtnWrap>
                    </AccountBox>
                </AccountWrap>

            </Content>
        </Container>
    );
};

export default SignUp;