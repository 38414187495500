import challengeStyles from "../../common/css/Challenge.module.css";
import EnterItem from "../common/EnterItem";
import commonStyles from "../../common/css/Common.module.css";
import RadioItem from "../common/RadioItem";
import accountStyles from "../../common/css/Account.module.css";
import ConsentItem from "../account/ConsentItem";
import ConsentWrap from "../account/ConsentWrap";
import {useEffect, useState} from "react";
import _ from "lodash";

export const ApplicationMdInput = ({teamApplicationInfo, applicationMdInputChange}) => {
    return (
        !_.isEmpty(teamApplicationInfo) && (
                <EnterItem addClass={challengeStyles.enter_item}>
                    <label htmlFor={`item_value_${teamApplicationInfo.itemValueUid}`} className={challengeStyles.item_title}>{teamApplicationInfo.question}</label>
                    <div className={`${commonStyles.enter} ${challengeStyles.enter}`}>
                        <input
                            id={`item_value_${teamApplicationInfo.itemValueUid}`}
                            type={"text"}
                            onChange={(e) => applicationMdInputChange(e, teamApplicationInfo.itemValueUid)}
                            value={teamApplicationInfo.answer}
                        />
                    </div>
                </EnterItem>
        )
    );
};

export const ApplicationMdSelection = ({teamApplicationInfo, applicationMdRadioHandle}) => {
    return (
        !_.isEmpty(teamApplicationInfo) && (
                <EnterItem addClass={challengeStyles.enter_item}>
                    <div className={challengeStyles.item_title}>{teamApplicationInfo.question}</div>
                    <div className={`${commonStyles.enter} ${challengeStyles.enter}`}>
                        <div className={challengeStyles.option_list}>
                            {teamApplicationInfo.options.map(option => (
                                <RadioItem key={option.itemOptionUid} addClass={challengeStyles.radio_item}>
                                    <label>
                                        <input
                                            id={`team_option_${teamApplicationInfo.itemValueUid}-${option.itemOptionUid}`}
                                            name={`team_option_${teamApplicationInfo.itemValueUid}`}
                                            type="radio"
                                            value={option.itemOptionUid}
                                            onClick={(e) => applicationMdRadioHandle(e, teamApplicationInfo.itemValueUid)}
                                            checked={option.itemOptionUid === Number(teamApplicationInfo.answer)}
                                        />
                                        <span>{option.content}</span>
                                    </label>
                                </RadioItem>
                            ))}
                        </div>
                    </div>
                </EnterItem>
        )
    );
};

export const ApplicationMdConsent = ({teamApplicationInfo, teamApplicationInfos, setTeamApplicationInfos, open}) => {
    const [checked, setChecked] = useState(Boolean(teamApplicationInfo.answer) === true ? true : false);

    useEffect(() => {
        setTeamApplicationInfos(teamApplicationInfos.map(info => (
            info.itemValueUid === teamApplicationInfo.itemValueUid ? {...info, answer: checked} : info
        )));
    }, [checked]);

    useEffect(() => {
        setChecked(Boolean(teamApplicationInfo.answer) === true ? true : false);
    }, [open])

    return (
        !_.isEmpty(teamApplicationInfo) && (
            <EnterItem addClass={challengeStyles.enter_item}>
                <ConsentWrap addClass={`${challengeStyles.consent_wrap}`}>
                    <ConsentItem addClass={challengeStyles.consent_item}>
                        <div className={`${accountStyles.consent_box} ${challengeStyles.consent_box}`} tabIndex="1">
                            {teamApplicationInfo.question}
                        </div>
                        <EnterItem addClass={challengeStyles.enter_item}>
                            <div className={`${commonStyles.enter} ${challengeStyles.enter}`}>
                                <div className={`${commonStyles.check_item} ${challengeStyles.check_item}`}>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={checked}
                                            onChange={({ target: { checked } }) => setChecked(checked)}
                                        />
                                        <span><em className="essential">[필수]</em>동의합니다.</span>
                                    </label>
                                </div>
                            </div>
                        </EnterItem>
                    </ConsentItem>
                </ConsentWrap>
            </EnterItem>
        )
    );
};