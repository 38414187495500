import commonStyles from "../../common/css/Common.module.css";
import {useDialog} from "../../common/hook/DialogContext";

const CustomConfirm = () => {
    const {confirms, removeConfirm} = useDialog();

    return (
        confirms.map(confirm => (
            <div className={commonStyles.dialog_wrap}>
                <div>
                    <div className={commonStyles.middle}>
                        {confirm.message}
                    </div>
                    <div className={commonStyles.bottom}>
                        <div className={commonStyles.btns}>
                            <a
                                href="#"
                                className={commonStyles.cc_btn}
                                onClick={
                                    (e) => {
                                        e.preventDefault();
                                        removeConfirm(confirm.id);
                                        confirm.afterConfirm();
                                    }
                                }
                            >
                                <span>확인</span>
                            </a>
                            <a
                                href="#"
                                className={`${commonStyles.cc_btn} ${commonStyles.bg_white}`}
                                onClick={
                                    (e) => {
                                        e.preventDefault();
                                        removeConfirm(confirm.id);
                                        confirm.afterCancel();
                                    }
                                }
                            >
                                <span>취소</span>
                            </a>
                        </div>
                    </div>
                    <button
                        type="button"
                        className={commonStyles.md_close_btn}
                        onClick={
                            () => {
                                removeConfirm(confirm.id);
                                confirm.afterCancel();
                            }
                        }
                    >
                        <span className={commonStyles.sr_only}>팝업 닫기</span>
                    </button>
                </div>
            </div>
        ))
    );
};

export default CustomConfirm;