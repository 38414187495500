import {useEffect, useState} from "react";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import _ from "lodash";
import challengeStyles from "../../../common/css/Challenge.module.css";
import commonStyles from "../../../common/css/Common.module.css";
import EditorContent from "../../common/EditorContent";
import BbsWrap from "./../BbsWrap";
import CommentDetail from "./CommentDetail";
import {useAuth} from "../../../common/hook/AuthContext";
import {useDialog} from "../../../common/hook/DialogContext";

const ChallengeBoardDetail = ({challengeuid}) => {

    const param = useParams();
    const {userUid, isLogin} = useAuth();
    const location = useLocation();
    const { addAlert, addConfirm } = useDialog();

    const [board, setBoard] = useState({
        uid: param.boardUid,
        challengeUid: challengeuid,
        title: '',
        content: '',
        creator: '',
        creatorUid: 0,
        createdDate: '',
        updatedDate: '',
    });

    const [commentReload, setCommentReload] = useState(true);
    const [parentComments, setParentComments] = useState([]);

    const {uid, challengeUid, title, content, creator, creatorUid, createdDate, updatedDate} = board;

    const getBoardInfo = () => {
        fetch(`/v1/challenge-boards/${uid}`, {
        }).then(async res => {
            if (res.status === 200) {
                return res.json();
            } else {
                const json = await res.json();
                addAlert(json.message);
            }
        })
            .then((data) => {
                setBoard({
                    ...board,
                    challengeUid: data.challengeUid,
                    categoryUid: data.categoryUid,
                    title: data.title,
                    content: data.content,
                    top: data.top,
                    use: data.use,
                    secret: data.secret,
                    memo: data.memo,
                    creator: data.creator,
                    creatorUid: data.creatorUid,
                    updatedDate: data.updatedDate,
                    createdDate: data.createdDate,
                })
            }).catch(e => console.log(e.message));
    }

    const getComments = () => {
        fetch(`/v1/comment/${uid}`, {
        }).then(async res => {
            if (res.status === 200) {
                return res.json();
            } else {
                const json = await res.json();
                addAlert(json.message);
            }
        })
            .then((data) => {
                setParentComments(data);
            }).catch(e => console.log(e.message));
    }

    useEffect(() => {
        getBoardInfo();
    }, []);

    useEffect(() => {
        getComments();
    }, [commentReload]);

    return (
        // <>
        //     {!isEmptyObj(title) ?
                <BbsWrap>
                    <div className={challengeStyles.view_wrap} >
                        <div className={challengeStyles.view_top}>
                            <div className={`${challengeStyles.title}  ${!board.secret ? '' : challengeStyles.lock}`}>{title}</div>
                            <div className={challengeStyles.info}>
                                <span>{creator}</span>
                                <span>{createdDate}</span>
                            </div>
                        </div>
                        <div className={challengeStyles.view_bottom}>
                            <div className={challengeStyles.editor_content}>
                                <EditorContent>{content}</EditorContent>
                            </div>
                        </div>
                    </div>
                    <div className={challengeStyles.bottom_btn_wrap}>
                        <Link
                            to={`/challenge/${challengeUid}/board`}
                            state={{
                                prevPage: location.state?.prevPage,
                            }}
                        >
                            <button type="button" className={`${commonStyles.cc_btn} ${challengeStyles.cc_btn} ${commonStyles.bg_white}`}>
                                <span>목록</span>
                            </button>
                        </Link>
                        { userUid === creatorUid &&
                            <Link
                                to={`/challenge/${challengeUid}/board/${uid}/update`}
                                state={{
                                    prevPage: location.state?.prevPage,
                                }}
                            >
                                <button type="button" className={`${commonStyles.cc_btn} ${challengeStyles.cc_btn}`}>
                                    <span>수정</span>
                                </button>
                            </Link>
                        }
                    </div>

                    <CommentDetail
                        board={board}
                        userUid={userUid}
                        parentComments={parentComments}
                        commentReload={commentReload}
                        setCommentReload={setCommentReload}
                        isLogin={isLogin}
                    />
                        
                </BbsWrap>

        //         :
        //         <Loading/>}
        // </>
    )
}

export default ChallengeBoardDetail;