import challengeStyles from "../../common/css/Challenge.module.css";
import TabItem from "./TabItem";
import {isEmptyObj} from "../../Utils/CommonUtils";
import {useDialog} from "../../common/hook/DialogContext";
import {useAuth} from "../../common/hook/AuthContext";

const ChallengeTopTabs = ({ tabMenu, uid, challenge, teamUid }) => {
    const baseUrl = `/challenge/${uid}`;
    const {addAlert, addConfirm} = useDialog();
    const { isLogin } = useAuth();

    return (
        <div className={challengeStyles.top_tabs}>
            <TabItem isOn={tabMenu === "info"} url={baseUrl + `/info/overview`}><span>대회 안내</span></TabItem>
            {isEmptyObj(challenge) ?
                <TabItem isOn={tabMenu === "data"} url={baseUrl + `/data/agreement`}><span>데이터</span></TabItem>
                :
                challenge.challengeTeamMember ?
                    <TabItem isOn={tabMenu === "data"} url={baseUrl + `/data/agreement`}><span>데이터</span></TabItem>
                    :
                    <button type="button" onClick={() => addAlert("데이터는 대회 참가자만 확인할 수 있습니다.")} className={challengeStyles.tab_item}><span>데이터</span></button>

            }
            <TabItem isOn={tabMenu === "board"} url={baseUrl + `/board`}><span>게시판</span></TabItem>
            <TabItem isOn={tabMenu === "team"} url={baseUrl + `/team`}><span>팀</span></TabItem>
            <TabItem isOn={tabMenu === "ranking"} url={baseUrl + `/public-ranking`}><span>순위</span></TabItem>
            {(isLogin && challenge.challengeTeamMember) && (
                <TabItem isOn={tabMenu === "submit"} url={baseUrl + `/submit`}><span>제출</span></TabItem>
            )}
        </div>
    );
};

export default ChallengeTopTabs;