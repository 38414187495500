import commonStyles from "../../common/css/Common.module.css";
import challengeStyles from "../../common/css/Challenge.module.css";
import ChallengeInner from "./ChallengeInner";
import ChallengeTopTabs from "./ChallengeTopTabs";
import {Link} from "react-router-dom";
import {useAuth} from "../../common/hook/AuthContext";

const ChallengeTop = ({ tabMenu, uid, challenge, teamUid }) => {
    const {isLogin} = useAuth();

    const scheduleDetailHtml = (scheduleDetail) => {
        return {__html: scheduleDetail};
    }

    return (
        <div className={challengeStyles.top}>
            <ChallengeInner>
                <div className={challengeStyles.inner_top}>
                    <div className={challengeStyles.left}>
                        <div className={`${challengeStyles.status} ${challengeStyles.underway}`}>
                            <span>
                                {
                                    challenge.openState === "OPENED" ?
                                        "진행중"
                                        :
                                        challenge.openState === "CLOSED" ?
                                            "대회 종료"
                                            :
                                            "진행 예정"
                                }
                            </span>
                        </div>
                        <div className={challengeStyles.challenge_title}>
                            {challenge.name}
                        </div>
                        <div className={challengeStyles.challenge_desc}>{challenge.type} | {challenge.intro}</div>
                    </div>
                    <div className={challengeStyles.right}>
                        <div className={challengeStyles.challenge_thumb}>
                            <img src={`/v1/image/${challenge.thumbnailUid}`} alt="" />
                        </div>
                    </div>
                </div>
                <div className={challengeStyles.inner_bottom}>
                    <div className={challengeStyles.left}>
                        <div className={challengeStyles.challenge_info}>
                            <div className={challengeStyles.info_item}>
                                <div className={challengeStyles.name}>주최</div>
                                <div>{challenge.host}</div>
                            </div>
                            <div className={challengeStyles.info_item}>
                                <div className={challengeStyles.name}>상금</div>
                                <div>{challenge.reward}</div>
                            </div>
                            <div className={challengeStyles.info_item}>
                                <div className={challengeStyles.name}>일정</div>
                                <div>{challenge.schedule}</div>
                            </div>
                            <div className={challengeStyles.info_item}>
                                <div className={challengeStyles.name}>참여 팀</div>
                                <div>{challenge.participationTeamCnt}</div>
                            </div>
                            {challenge.scheduleDetail && (
                                <div className={challengeStyles.hidden_detail}>
                                    <div dangerouslySetInnerHTML={scheduleDetailHtml(challenge.scheduleDetail)}>
                                        {/*<ul>*/}
                                        {/*    <li>*/}
                                        {/*        <div className={challengeStyles.date}>04.01</div>*/}
                                        {/*        <div className={challengeStyles.txt}>대회시작</div>*/}
                                        {/*    </li>*/}
                                        {/*</ul>*/}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    {challenge.openState !== "CLOSED" && !challenge.challengeTeamMember && isLogin ?
                        <div className={challengeStyles.right}>
                            <Link
                                to={`/challenge/${uid}/team`}
                                className={`${commonStyles.cc_btn} ${challengeStyles.cc_btn}`}
                            >
                                <span>참가하기</span>
                            </Link>
                        </div>
                        :
                        <div className={challengeStyles.right} />
                    }

                </div>

                <ChallengeTopTabs tabMenu={tabMenu} uid={uid} challenge={challenge} teamUid={teamUid} />
            </ChallengeInner>
        </div>
    );
};

export default ChallengeTop;