import React from "react";
import { useState } from "react";
import _ from "lodash";
import commonStyles from "../../../common/css/Common.module.css";
import challengeStyles from "../../../common/css/Challenge.module.css";
import useKeepDoubleClick from "../../../common/hook/useKeepDoubleClick";
import { isBlankStr } from "../../../Utils/ValidateUtils";
import { isEmptyObj } from "../../../Utils/CommonUtils";
import {useDialog} from "../../../common/hook/DialogContext";

const CommentDetail = ({ board, userUid, parentComments, commentReload, setCommentReload, isLogin }) => {
    const [clicked, preventDoubleClick] = useKeepDoubleClick();
    const [showComment, setShowComment] = useState(0);
    const [comment, setComment] = useState("");
    const [childComment, setChildComment] = useState("");
    const { addAlert, addConfirm } = useDialog();
    const {uid} = board;

    // 댓글, 답글 생성(parentComment.uid가 있을시 child로 path 변경)
    const addComment = (content, parentUid) => {
        addConfirm(
            "댓글을 등록하시겠습니까?",
            () => {
                fetch(`/v1/comment${isEmptyObj(parentUid) ? "" : "/child"} `, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    method: "post",
                    credentials: "include",
                    redirect: "follow",
                    body: JSON.stringify({
                        boardUid: uid,
                        parentUid: parentUid,
                        content: content,
                    }),
                }).then((response) => {
                    clicked.current = false;

                    if (response.ok) {
                        addAlert("등록 되었습니다.");
                        isEmptyObj(parentUid) ? setComment("") : setChildComment("");
                        setShowComment(0);
                        setCommentReload(!commentReload);
                    } else if (response.status === 400 || response.status === 401) {
                        response.json().then(json => {
                            addAlert(json.message);
                        })
                    } else {
                        throw new Error("등록할 수 없습니다. 다시 시도해주세요.");
                    }
                }).catch(e => {
                    addAlert(e.message);
                });
            },
            () => clicked.current = false
        );

    };

    // 답글, 댓글 삭제
    const deleteComment = (uid) => {
        addConfirm(
            "댓글을 삭제하시겠습니까?",
            () => {
                fetch(`/v1/comment/${uid}`, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    method: "put",
                    credentials: "include",
                    redirect: "follow",
                }).then((response) => {
                    clicked.current = false;
                    if (response.ok) {
                        addAlert("삭제 되었습니다.")
                        setShowComment(0);
                        setCommentReload(!commentReload);
                    } else if (response.status === 400 || response.status === 401) {
                        response.json().then(json => {
                            addAlert(json.message);
                        })
                    } else {
                        throw new Error("삭제할 수 없습니다. 다시 시도해주세요.");
                    }
                }).catch(e => {
                    addAlert(e.message);
                });
            },
            () => clicked.current = false
        );

    };

    // 댓글 vaildation
    const validation = () => {
        if (isBlankStr(comment)) {
          addAlert("댓글 내용을 입력해 주세요.");
          return false;
        }
        return true;
    };

    // 답글 vaildation
    const childValidation = () => {
        if (isBlankStr(childComment)) {
          addAlert("댓글 내용을 입력해 주세요.");
          return false;
        }
        return true;
    };

    // 답글쓰기 화면 활성화
    const showCommentEdit = (uid) => {
        setChildComment("")
        setShowComment(uid);
        setCommentReload(!commentReload);
    }

    return (
        <div className={challengeStyles.comment_wrap}>
            {/* 댓글 쓰기 */}
            <div className={challengeStyles.comment_enter}>
                <div className={`${commonStyles.enter_item} ${challengeStyles.enter_item}`}>
                    <div className={`${commonStyles.enter} ${challengeStyles.enter} ${commonStyles.flex} ${challengeStyles.flex}`}>
                    <input id="comment" type="text" placeholder="댓글을 입력해 주세요." value={comment}  maxLength="200"
                        onChange={(e) => setComment(e.target.value)} onBlur={(e) => setComment(e.target.value)} disabled={!isLogin}/>
                        <button
                            type="button"
                            className={`${commonStyles.cc_btn} ${challengeStyles.cc_btn} ${commonStyles.bg_darkgray}`}
                            onClick={
                                () => {
                                    isLogin ?
                                        preventDoubleClick(() => addComment(comment), validation)
                                        :
                                        addAlert("로그인이 필요합니다.");
                                }
                            }
                        >
                            <span>등록</span>
                        </button>
                    </div>
                </div>
            </div>

            <div className={challengeStyles.comment_list}>
                {!isEmptyObj(parentComments) && parentComments.map(parentComment => (
                    <>
                        {/* 댓글 */}
                        <div key={parentComment.uid} className={`${challengeStyles.comment_item} ${!isEmptyObj(childComment.deleteDate) ? challengeStyles.delete : challengeStyles.my_comment}`}>
                            <div className={challengeStyles.comment}>
                                {!isEmptyObj(parentComment.deleteDate) ? 
                                    "삭제된 댓글입니다."
                                    :
                                    parentComment.content
                                }
                            </div>
                            {/* 답글쓰기 버튼 (클릭시 답글 쓰기화면 출력) */}
                            <div className={challengeStyles.info}>
                                <span>
                                    {!isEmptyObj(parentComment.deleteDate) ? 
                                        "삭제글"
                                        :
                                        parentComment.creator
                                    }
                                </span>
                                <span>{parentComment.updatedDate}</span>
                                {isEmptyObj(parentComment.deleteDate) && (
                                    <button
                                        className={`${challengeStyles.cc_btn} ${challengeStyles.underline}`}
                                        onClick={
                                            () => {
                                                isLogin ?
                                                    showComment !== parentComment.uid ? showCommentEdit(parentComment.uid) : showCommentEdit(0)
                                                    :
                                                    addAlert("로그인이 필요합니다.");
                                            }
                                        }
                                    >
                                        <span>답글쓰기</span>
                                    </button>
                                )}
                            </div>
                            {/* 댓글 삭제 */}
                            {(isEmptyObj(parentComment.deleteDate) && userUid === parentComment.creatorUid ) && (
                                <button type="button" className={challengeStyles.delete_btn} onClick={() => preventDoubleClick(() => deleteComment(parentComment.uid))}>
                                    <span>삭제</span>
                                </button>
                            )}
                        </div> 
                        {/* /답글 쓰기/ */}
                        {(showComment === parentComment.uid) && (
                            <div className={`${challengeStyles.comment_enter} ${challengeStyles.reply}`}>
                                <div className={`${commonStyles.enter_item} ${challengeStyles.enter_item}`}>
                                    <div className={`${commonStyles.enter} ${challengeStyles.enter} ${commonStyles.flex} ${challengeStyles.flex}`}>
                                        <input id="childComment" type="text" placeholder="댓글을 입력해 주세요." value={childComment}  maxLength="200" onChange={(e) => setChildComment(e.target.value)} onBlur={(e) => setChildComment(e.target.value)}/>
                                        <button type="button" className={`${commonStyles.cc_btn} ${challengeStyles.cc_btn} ${commonStyles.bg_gray}`} onClick={() => preventDoubleClick(() => addComment(childComment, parentComment.uid), childValidation)}>
                                            <span>등록</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                        {/* 답글 */}
                        {!isEmptyObj(parentComment.childComments) && parentComment.childComments.map(childComment => (
                            <div key={childComment.uid} className={`${challengeStyles.comment_item} ${ !isEmptyObj(childComment.deleteDate) ? challengeStyles.delete : challengeStyles.reply}`}>
                                <div className={challengeStyles.comment}>
                                    {!isEmptyObj(childComment.deleteDate) ? 
                                        "삭제된 댓글입니다."
                                        :
                                        childComment.content
                                    }
                                </div>
                                <div className={challengeStyles.info}>
                                    <span>
                                        {!isEmptyObj(childComment.deleteDate) ? 
                                            "삭제글"
                                            :
                                            childComment.creator
                                        }
                                    </span>
                                    <span>{childComment.updatedDate}</span>
                                </div>
                                {/* 답글 삭제 */}
                                {(isEmptyObj(childComment.deleteDate) && userUid === childComment.creatorUid) && (
                                    <button type="button" className={challengeStyles.delete_btn} onClick={() => preventDoubleClick(() => deleteComment(childComment.uid))}>
                                        <span>삭제</span>
                                    </button>
                                )}
                            </div> 
                        ))}
                    </>
                ))}
            </div>
        </div>
    );
};


export default CommentDetail;