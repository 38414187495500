import {useEffect, useState} from "react";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import _ from "lodash";
import commonStyles from "../../../common/css/Common.module.css";
import challengeStyles from "../../../common/css/Challenge.module.css";
import BbsWrap from "../BbsWrap";
import Ckeditor from "../../common/CkEditor";
import Enter from "../../common/Enter";
import {useAuth} from "../../../common/hook/AuthContext";
import useKeepDoubleClick from "../../../common/hook/useKeepDoubleClick";
import { isEmptyObj } from "../../../Utils/CommonUtils";
import {useDialog} from "../../../common/hook/DialogContext";

const ChallengeTeamCreate = ({challengeUid, innerMenu}) => {
    const { isLogin, userUid } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const param = useParams();
    const { addAlert, addConfirm } = useDialog();
    
    const [board, setBoard] = useState({
        uid: innerMenu === "update" ? param.boardUid : null,
        categoryUid: 0,
        title: null,
        content: null,
        isSecret: false,
    });

    const {uid, categoryUid, title, content, isUse, isSecret} = board;

    const [boardCategorys, setBoardCategorys] = useState([]);
    const [clicked, preventDoubleClick] = useKeepDoubleClick();

    // 게시판 카테고리 목록
    const getBoardCategory = () => {
        fetch(`/v1/challenge-boards/boardCategory/${challengeUid}`)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.json().then(json => {
                        addAlert(
                            json.message,
                            () => navigate(`/challenge/${challengeUid}/board`)
                        );
                    })
                }
            })
            .then((data) => {
                setBoardCategorys(data);
                
                if(!isEmptyObj(data) && innerMenu === "create"){
                    setBoard({
                        ...board,
                        categoryUid: data[0].uid
                    })
                }
            }).catch(e => console.log(e.message));
    }

    // 업데이트 시 게시물 정보
    const getBoardInfo = () => {
        fetch(`/v1/challenge-boards/${uid}`, {
        }).then(async res => {
            if (res.status === 200) {
                return res.json();
            } else {
                const json = await res.json();
                addAlert(json.message);
            }
        })
            .then((data) => {
                setBoard({
                    ...board,
                    categoryUid: data.categoryUid,
                    title: data.title,
                    content: data.content,
                    isUse: data.use,
                    isSecret: data.secret,
                })
            }).catch(e => console.log(e.message));
    }

    // 체크박스 변경 핸들러
    const handleCheckboxChange = () => {
        setBoard({
            ...board,
            isSecret: !isSecret,
        })
    };

    useEffect(() => {
        if (!isLogin) {
            addAlert(
                "로그인이 필요합니다.",
                () => navigate(`/challenge/${challengeUid}/board`)
            );
        }
        
        getBoardCategory();
        
        if(innerMenu === "update"){
            getBoardInfo();
        }
    }, []);
    
    // 게시글 등록
    const insertData = () => {
        addConfirm(
            "게시글을 등록하시겠습니까?",
            () => {
                fetch("/v1/challenge-boards", {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    method: "post",
                    credentials: "include",
                    redirect: "follow",
                    body: JSON.stringify({
                        challengeUid: challengeUid,
                        categoryUid: categoryUid,
                        title: title,
                        content: content,
                        isUse: isUse,
                        isSecret: isSecret,
                    }),
                }).then((response) => {
                    clicked.current = false;

                    if (response.ok) {
                        addAlert(
                            "등록 되었습니다.",
                            () => navigate(`/challenge/${challengeUid}/board`)
                        );
                    } else if (response.status === 400 || response.status === 401) {
                        response.json().then(json => {
                            addAlert(json.message);
                        })
                    } else {
                        throw new Error("등록할 수 없습니다. 다시 시도해주세요.");
                    }
                }).catch(e => {
                    addAlert(e.message);
                });
            },
            () => clicked.current = false
        );
    };

    // 게시글 수정
    const updateData = (isDelete) => {
        addConfirm(
            "게시글을 수정하시겠습니까?",
            () => {
                fetch(`/v1/challenge-boards/${uid}`, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    method: "put",
                    credentials: "include",
                    redirect: "follow",
                    body:
                        JSON.stringify({
                            challengeUid: challengeUid,
                            categoryUid: categoryUid,
                            title: title,
                            content: content,
                            isUse: isUse,
                            isSecret: isSecret,
                        }),
                }).then((response) => {
                    clicked.current = false;

                    if (response.ok) {
                        addAlert(
                            `수정 되었습니다.`,
                            () => navigate(`/challenge/${challengeUid}/board/${uid}`, {state: {prevPage: location.state?.prevPage}})
                        );
                    } else if (response.status === 400 || response.status === 401) {
                        response.json().then(json => {
                            addAlert(json.message);
                        })
                    } else {
                        throw new Error(`수정할 수 없습니다. 다시 시도해주세요.`);
                    }
                }).catch(e => {
                    addAlert(e.message);
                });
            },
            () => clicked.current = false
        )
    };

    // 게시글 삭제
    const deleteData = () => {
        addConfirm(
            "게시글을 삭제하시겠습니까?",
            () => {
                fetch(`/v1/challenge-boards/${uid}`, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    method: "put",
                    credentials: "include",
                    redirect: "follow",
                    body:
                        JSON.stringify({
                            challengeUid: challengeUid,
                            categoryUid: categoryUid,
                            title: title,
                            content: content,
                            isUse: false,
                            isSecret: isSecret,
                        }),
                }).then((response) => {
                    clicked.current = false;

                    if (response.ok) {
                        addAlert(
                            `삭제 되었습니다.`,
                            () => navigate(`/challenge/${challengeUid}/board`, {state: {prevPage: location.state?.prevPage}})
                        );
                    } else if (response.status === 400 || response.status === 401) {
                        response.json().then(json => {
                            addAlert(json.message);
                        })
                    } else {
                        throw new Error(`삭제할 수 없습니다. 다시 시도해주세요.`);
                    }
                }).catch(e => {
                    addAlert(e.message);
                });
            },
            () => clicked.current = false
        );

    };

    const validation = () => {
        if (categoryUid === 0 || !categoryUid) {
            addAlert("카테고리를 선택해주세요.");
            return;
        }

        if (isEmptyObj(title)) {
            addAlert("제목을 입력해주세요.");
            return;
        }

        if (isEmptyObj(content)) {
            addAlert("내용을 입력해주세요.");
            return;
        }

        return true;
    };

    const submit = () => {        
        innerMenu === "update" ?
            preventDoubleClick(updateData, validation)
            :
            preventDoubleClick(insertData, validation);
    };

    const deleteSubmit = () => {
            preventDoubleClick(deleteData);
    };


    

    return (
        <BbsWrap>
            {isLogin && (
                <>
                    <div className={challengeStyles.top}>
                        <div className={challengeStyles.table_title}>{innerMenu === "update" ? "글 수정" : "글 쓰기"}</div>
                    </div>
                    <div className={challengeStyles.enter_wrap}>
                        <div className={challengeStyles.enter_item}>
                            <div className={challengeStyles.enter}>
                                <label for="bbs_type" className={commonStyles.sr_only}><span>글쓰기 종류 선택</span></label>
                                <select id="bbs_type" value={categoryUid} onChange={(e) => { setBoard({...board, categoryUid: Number(e.target.value)}); }}>
                                    <option disabled selected>종류를 선택해주세요.</option>
                                    {boardCategorys.map(boardCategory => (
                                        <option key={boardCategory.uid} value={boardCategory.uid}>{boardCategory.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className={challengeStyles.enter_item}>
                            <div className={challengeStyles.enter}>
                                <label htmlFor="bbs_title" className={commonStyles.sr_only}>제목 입력</label>
                                <Enter
                                    id={"bbs_title"}
                                    type={"text"}
                                    addClass={challengeStyles.enter}
                                    value={title}
                                    inputChange={(e) => setBoard({...board, title: e.target.value})}
                                    placeholder={"제목을 입력해 주세요."}
                                />
                            </div>
                        </div>
                        <div className={challengeStyles.enter_item}>
                            <Ckeditor content={content} setContent={ (newContent) => setBoard((prevState) => ({...prevState, content: newContent})) } addClass={challengeStyles.enter} />
                        </div>
                        <div className={`${commonStyles.enter_item} ${challengeStyles.enter_item}`}>
                            <div className={`${commonStyles.enter} ${challengeStyles.enter}`}>
                                <div className={`${commonStyles.check_item} ${challengeStyles.check_item}`}>
                                    <label>
                                        <input type="checkbox" checked={isSecret} onChange={() => handleCheckboxChange()}/>
                                        <span>비밀글</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={challengeStyles.bottom_btn_wrap}>
                        
                        <Link
                            to={`/challenge/${challengeUid}/board${innerMenu === "update" ? `/${uid}` : '' }`}
                            state={{
                                prevPage: location.state?.prevPage,
                            }}
                        >
                            <button type="button" className={`${commonStyles.cc_btn} ${challengeStyles.cc_btn} ${commonStyles.bg_white}`}>
                                <span>취소</span>
                            </button>
                        </Link>
                        {innerMenu === "update" ? (
                            <>
                                <button type="button" className={`${commonStyles.cc_btn} ${challengeStyles.cc_btn}`} onClick={submit}>
                                    <span>수정</span>
                                </button>
                                <button type="button" className={`${commonStyles.cc_btn} ${challengeStyles.cc_btn} ${commonStyles.bg_gray}`} onClick={deleteSubmit}>
                                    <span>삭제</span>
                                </button>
                            </>
                        ) : (
                            <button type="button" className={`${commonStyles.cc_btn} ${challengeStyles.cc_btn}`} onClick={submit}>
                                <span>등록</span>
                            </button>
                        )}
                    </div>
                </>
            )}
        </BbsWrap>
    );
};

export default ChallengeTeamCreate;