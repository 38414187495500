import challengeStyles from "../../common/css/Challenge.module.css";
import commonStyles from "../../common/css/Common.module.css";
import EnterItem from "../common/EnterItem";
import ConsentItem from "../account/ConsentItem";
import accountStyles from "../../common/css/Account.module.css";

const ChallengeConsentItem = ({addClass, agreement, checked, onChange}) => {

    const contentHtml = () => {
        return {__html: agreement.content};
    }

    return (
        <ConsentItem addClass={addClass}>
            <div className={`${accountStyles.consent_box} ${challengeStyles.consent_box}`} tabIndex="1" dangerouslySetInnerHTML={contentHtml()}>
            </div>
            <EnterItem addClass={challengeStyles.enter_item}>
                <div className={`${commonStyles.enter} ${challengeStyles.enter}`}>
                    <div className={`${commonStyles.check_item} ${challengeStyles.check_item}`}>
                        <label>
                            <input
                                type="checkbox"
                                checked={checked}
                                onChange={onChange}
                            />
                            <span><em className={commonStyles.essential}>[필수]</em>{agreement.title}에 동의 합니다.</span>
                        </label>
                    </div>
                </div>
            </EnterItem>
        </ConsentItem>
    );
};

export default ChallengeConsentItem;