import challengeStyles from "../../common/css/Challenge.module.css";
import commonStyles from "../../common/css/Common.module.css";
import EnterItem from "../common/EnterItem";
import MyPageEnter from "./MyPageEnter";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Url from "../../common/Url";
import {emailPattern, namePattern} from "../../Utils/ValidateUtils";
import {FormInputHelper} from "../../common/php_lib/form-input-helper";
import ResetPasswordMd from "./ResetPasswordMd";
import {Loading} from "../../routes/basic/Unknown";
import useKeepDoubleClick from "../../common/hook/useKeepDoubleClick";
import {useAuth} from "../../common/hook/AuthContext";
import {useDialog} from "../../common/hook/DialogContext";

const MyPageInfo = () => {
    const { isLogin, loginCheck, setIsLogin } = useAuth();
    const [userData, setUserData] = useState({
        userId: '',
        userName: '',
        email: '',
        dept: '',
        position: '',
        isClinician: ''
    });
    const navigate = useNavigate();
    const [clicked, preventDoubleClick] = useKeepDoubleClick();
    const [resetPasswordMdOpen, setResetPasswordMdOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const {addAlert, addConfirm} = useDialog();

    const inputChange = (event) => {
        const { name, value } = event.target;
        setUserData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const isClinicianChange = (event) => {
        setUserData(prevState => ({
            ...prevState,
            ['isClinician']: event.target.value === "true" ? true : false
        }));
    };


    const getUserInfo = () => {
        setIsLoading(true);
        fetch(`/v1/users/info`)
            .then((response) => {
                if (response.ok) {
                    setIsLoading(false);
                    return response.json();
                } else {
                    return response.json().then(json => {
                        addAlert(
                            json.message,
                            () => navigate(Url.login)
                            // () => navigate(Url.home)
                        );
                    })
                }
            })
            .then((json) => {
                setUserData(json);
            });
    }

    useEffect(() => {
        if (isLogin === false) {
            setIsLoading(true);
            addAlert(
                "로그인이 필요합니다.",
                () => navigate(Url.login)
            );
        } else {
            getUserInfo();
        }
    }, []);

    const updateData = () => {
        addConfirm(
            "내 정보를 수정하시겠습니까?",
            () => {
                fetch(`/v1/users`, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    method: "put",
                    credentials: "include",
                    redirect: "follow",
                    body: JSON.stringify(userData),
                }).then((response) => {
                    clicked.current = false;
                    if (response.ok) {
                        addAlert(
                            "수정 되었습니다.",
                            () => getUserInfo()
                        );

                    } else if (response.status === 400) {
                        response.json().then(json => {
                            addAlert(json.message);
                        })
                    } else if (response.status === 401) {
                        response.json().then(json => {
                            setIsLoading(true);
                            setIsLogin(false);
                            addAlert(json.message, () => navigate(Url.login));
                        })
                    }else {
                        throw new Error("수정할 수 없습니다. 다시 시도해주세요.");
                    }
                }).catch(e => {
                    addAlert(e.message);
                });
            },
            () => clicked.current = false
        )

    };

    const validation = () => {
        if (!namePattern.test(userData.userName) || !userData.userName) {
            addAlert("이름을 올바르게 입력해주세요");
            return false;
        }
        if (!emailPattern.test(userData.email) || !userData.email) {
            addAlert("이메일을 올바르게 입력해주세요");
            return false;
        }

        return true;
    };

    const submit = () => {
        preventDoubleClick(updateData, validation);
    };

    useEffect(() => {
        FormInputHelper.initKeyUpChecker();
        FormInputHelper.initKeyDownChecker();
        FormInputHelper.initCharacterLimiter();
    }, []);

    return (
        <>
            {!isLoading ?
                <div className={challengeStyles.mypage_enter_box}>
                    <div className={`${commonStyles.enter_wrap} ${challengeStyles.enter_wrap}`}>
                        <EnterItem addClass={challengeStyles.enter_item}>
                            <MyPageEnter
                                id={"user_id"}
                                type={"text"}
                                readOnly={true}
                                label={"아이디"}
                                value={userData.userId}
                            />
                        </EnterItem>
                        <EnterItem addClass={challengeStyles.enter_item}>
                            <MyPageEnter
                                id={"user_name"}
                                type={"text"}
                                label={"이름"}
                                name={"userName"}
                                value={userData.userName}
                                inputChange={(e) => inputChange(e)}
                                maxLength={30}
                                dataType={"user_name"}
                            />
                        </EnterItem>
                        <EnterItem addClass={challengeStyles.enter_item}>
                            <MyPageEnter
                                id={"user_email"}
                                type={"text"}
                                label={"이메일"}
                                name={"email"}
                                value={userData.email}
                                inputChange={(e) => inputChange(e)}
                                maxLength={50}
                                dataType={"email_address"}
                            />
                        </EnterItem>
                        <EnterItem addClass={challengeStyles.enter_item}>
                            <MyPageEnter
                                id={"user_team"}
                                type={"text"}
                                label={"소속 병원/기관"}
                                name={"dept"}
                                value={userData.dept}
                                inputChange={(e) => inputChange(e)}
                                maxLength={50}
                            />
                        </EnterItem>
                        <EnterItem addClass={challengeStyles.enter_item}>
                            <MyPageEnter
                                id={"user_position"}
                                type={"text"}
                                label={"직위"}
                                name={"position"}
                                value={userData.position}
                                inputChange={(e) => inputChange(e)}
                                maxLength={50}
                            />
                        </EnterItem>

                        <EnterItem addClass={challengeStyles.enter_item}>
                            <div className={`${commonStyles.item_title} ${challengeStyles.item_title}`}>임상의 여부</div>
                            <div className={`${commonStyles.enter} ${challengeStyles.enter} ${commonStyles.flex} ${challengeStyles.flex}`}>
                                <div className={`${commonStyles.radio_item} ${challengeStyles.radio_item}`}>
                                    <label>
                                        <input type="radio" onChange={isClinicianChange} value={"true"} checked={userData.isClinician} />
                                        <span>임상의</span>
                                    </label>
                                </div>
                                <div className={`${commonStyles.radio_item} ${challengeStyles.radio_item}`}>
                                    <label>
                                        <input type="radio" onChange={isClinicianChange} value={"false"} checked={!userData.isClinician} />
                                        <span>임상의 아님</span>
                                    </label>
                                </div>
                            </div>
                        </EnterItem>
                    </div>
                    <div className={`${commonStyles.bottom_btn_wrap} ${challengeStyles.bottom_btn_wrap}`}>
                        <button
                            type="button"
                            className={`${commonStyles.cc_btn} ${challengeStyles.cc_btn}`}
                            onClick={submit}
                        >
                            <span>수정하기</span>
                        </button>
                        <button
                            className={`${commonStyles.reset_password} ${challengeStyles.reset_password}`}
                            onClick={() => setResetPasswordMdOpen(true)}
                        >
                            <span>비밀번호 재설정</span>
                        </button>
                    </div>
                </div>
                :
                <Loading />
            }

            <ResetPasswordMd
                open={resetPasswordMdOpen}
                closedMd={() => setResetPasswordMdOpen(false)}
                setIsLogin={setIsLogin}
            />
        </>
    );
};

export default MyPageInfo;