
const ChallengeDataAgreement = ({agreement}) => {
    const contentHtml = () => {
        return {__html: agreement};
    }
    return (
        <div dangerouslySetInnerHTML={contentHtml()}></div>
    );
};

export default ChallengeDataAgreement;