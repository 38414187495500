import commonStyles from "../../common/css/Common.module.css";
import challengeStyles from "../../common/css/Challenge.module.css";
import EnterItem from "../common/EnterItem";
import Enter from "../common/Enter";
import myPageChallengeStyles from "../myPage/MyPageChallenge.module.css";
import {useEffect, useState} from "react";
import {useDialog} from "../../common/hook/DialogContext";
import useKeepDoubleClick from "../../common/hook/useKeepDoubleClick";
import {useNavigate} from "react-router-dom";
import Url from "../../common/Url";
import _ from "lodash";

const ChallengeSubmit = ({innerMenu, uid, challenge, teamUid}) => {
    const [submitList, setSubmitList] = useState([]);
    const [files, setFiles] = useState([]);
    const [commitMsg, setCommitMsg] = useState("");
    const {addAlert, addConfirm} = useDialog();
    const [clicked, preventDoubleClick] = useKeepDoubleClick();
    const navigate = useNavigate();

    const fileRegister = (e) => {
        if (e.target.files.length === 0) {
            return;
        }

        let validFileType = ["csv"];
        let fileVal = e.target.files[0].name.toString();
        let fileLength = fileVal.length;
        let fileDot = fileVal.lastIndexOf(".");

        let fileType = fileVal.substring(fileDot + 1, fileLength).toLowerCase();
        if (e.target.files.length === 0) {
            return;
        }
        if (!validFileType.includes(fileType)) {
            alert("제한된 파일 확장자 입니다.");
            return;
        }

        setFiles([e.target.files[0]]);
    };

    const getSubmitList = () => {
        fetch(`/v1/challenge-team/${teamUid}/submit`)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else if (response.status === 401) {
                    addAlert(
                        "로그인이 필요합니다.",
                        () => navigate(Url.login)
                    );
                } else {
                    return response.json().then(json => {
                        addAlert(json.message);
                    })
                }
            })
            .then((json) => {
                if (json === undefined) return;
                setSubmitList(json);
            });
    }

    useEffect(() => {
        if (teamUid !== 0 && teamUid) {
            getSubmitList();
        }
    },[teamUid]);

    const reset = () => {
        setFiles([]);
        setCommitMsg("");
        getSubmitList();
    }

    const submit = () => {
        addConfirm(
            "답안지를 제출하시겠습니까?",
            () => {
                var formData = new FormData();
                formData.append("challengeUid", encodeURIComponent(uid));
                formData.append("teamUid", encodeURIComponent(teamUid));
                formData.append("commitMsg", encodeURIComponent(commitMsg));
                formData.append("file", files[0]);

                fetch("/v1/challenge-team/submit", {
                    method: "post",
                    credentials: "include",
                    redirect: "follow",
                    body: formData,
                }).then((response) => {
                    clicked.current = false;
                    if (response.ok) {
                        addAlert("제출 되었습니다.", reset);
                    } else if (response.status === 400) {
                        response.json().then(json => {
                            addAlert(json.message, reset);
                        })
                    } else if (response.status === 401) {
                        addAlert(
                            "로그인이 필요합니다.",
                            () => navigate(Url.login)
                        );
                    } else {
                        throw new Error("제출할 수 없습니다. 다시 시도해주세요.");
                    }
                }).catch(e => {
                    addAlert(e.message);
                });
            },
            () => {
                clicked.current = false;
            }
        )
    };

    const validation = () => {
        if (commitMsg.trim() === "") {
            addAlert("커밋 메시지를 입력해주세요.");
            return;
        }

        if (_.isEmpty(files)) {
            addAlert("답안지를 업로드해주세요.")
            return;
        }

        return true;
    };

    const downloadSubmitFile = (submitUid, filename) => {
        const downloadUrl = `/v1/challenge-team/${teamUid}/submit/${submitUid}/download?filename=${filename}`;
        window.location.href = downloadUrl;
    }

    return (
        <div className={challengeStyles.bbs_wrap}>
            <div className={`${commonStyles.default_md} ${challengeStyles.default_md}`}>
                {challenge.openState === "OPENED" && (
                    <div className={commonStyles.con}>
                        <h1>답안지 제출</h1>
                        <div className={`${commonStyles.enter_wrap} ${challengeStyles.enter_wrap}`}>
                            <EnterItem addClass={`${challengeStyles.enter_item} ${commonStyles.file}`}>
                                <Enter
                                    addClass={`${challengeStyles.enter} ${commonStyles.flex}`}
                                    id={"upload_file"}
                                    type={"text"}
                                    title={"답안지 업로드"}
                                    placeholder={"답안지 업로드"}
                                    readOnly={true}
                                    label={"답안지"}
                                    value={files[0]?.name || ""}
                                >
                                    <input
                                        type="file"
                                        title="답안지 업로드"
                                        placeholder="답안지 업로드"
                                        multiple={false}
                                        onChange={fileRegister}
                                        accept=".csv"
                                    />
                                    <button
                                        type="button"
                                        className={`${commonStyles.cc_btn} ${challengeStyles.cc_btn} ${myPageChallengeStyles.cc_btn} ${commonStyles.bg_gray}`}
                                        title="파일찾기"
                                    >
                                        <span>파일찾기</span>
                                    </button>
                                </Enter>
                            </EnterItem>
                            <EnterItem addClass={challengeStyles.enter_item}>
                                <Enter
                                    addClass={challengeStyles.enter}
                                    id={"enter_msg"}
                                    label={"메시지 입력"}
                                    type={"text"}
                                    title={"메시지 입력"}
                                    placeholder={"메시지 입력"}
                                    value={commitMsg}
                                    inputChange={(e) => setCommitMsg(e.target.value)}
                                />
                            </EnterItem>
                        </div>
                        <div className={`${commonStyles.bottom_btn_wrap} ${challengeStyles.bottom_btn_wrap}`}>
                            <button
                                type="button"
                                className={`${commonStyles.cc_btn} ${challengeStyles.cc_btn}`}
                                style={{ width: "130px", fontSize: "16px", fontWeight: "400" }}
                                onClick={
                                    () => preventDoubleClick(submit, validation)
                                }
                            >
                                제 출
                            </button>
                        </div>
                    </div>
                )}
                <div className={commonStyles.con}>
                    <h1>제출 이력</h1>
                    <div className={commonStyles.cc_table}>
                        <table>
                            <caption><strong>제출 이력 테이블 입니다.</strong>파일명, 메시지, 점수, 제출일이 명시되어 있습니다.</caption>
                            <colgroup>
                                <col style={{ width: "170px" }}/>
                                <col />
                                <col style={{ width: "80px" }} />
                                <col style={{ width: "110px" }} />
                            </colgroup>
                            <thead>
                            <tr>
                                <th scope="col" className={`${commonStyles.ta_left} ${challengeStyles.ta_left}`}>
                                    <div>파일명</div>
                                </th>
                                <th scope="col" className={`${commonStyles.ta_left} ${challengeStyles.ta_left}`}>
                                    <div>메시지</div>
                                </th>
                                <th scope="col">
                                    <div>점수</div>
                                </th>
                                <th scope="col">
                                    <div>제출일</div>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                _.isEmpty(submitList) ?
                                    <tr>
                                        <td colSpan="4" className={commonStyles.no_con}>
                                            <div>등록된 글이 없습니다.</div>
                                        </td>
                                    </tr>
                                    :
                                    submitList.map(submit => (
                                        <tr key={submit.submitUid}>
                                            <td className={`${commonStyles.ta_left} ${challengeStyles.ta_left}`} data-th="파일명">
                                                <a
                                                    href="#"
                                                    className={commonStyles.underline_item}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        downloadSubmitFile(submit.submitUid, submit.filename)
                                                    }}
                                                >
                                                    <span className={commonStyles.line}>{submit.filename}</span>
                                                </a>
                                            </td>
                                            <td className={`${commonStyles.ta_left} ${challengeStyles.ta_left}`} data-th="메시지">
                                                {submit.commitMsg}
                                            </td>
                                            <td data-th="점 수">{submit.score}</td>
                                            <td data-th="제출일">{submit.createdDate}</td>
                                        </tr>
                                    ))
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChallengeSubmit;