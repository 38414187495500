import {Link} from "react-router-dom";
import Url from "../../common/Url";

const ChallengeList = ({ challenges }) => {

    return (
        <div className="challenge_list">
            {challenges.map(challenge => (
                <Link to={`/challenge/${challenge.uid}/info/overview`} className="challenge_item">
                    <div className="item_thumb">
                        <img src={`/v1/image/${challenge.thumbnailUid}`} alt="" />
                        <div className={`item_status ${challenge.openState === "OPENED" ? 'underway' : ''}`}>
                            {
                                challenge.openState === "OPENED" ?
                                    '진행중'
                                    :
                                    challenge.openState === "CLOSED" ?
                                        '대회 종료'
                                        :
                                        '진행 예정'
                            }
                        </div>
                    </div>
                    <div className="item_info">
                        <div className="item_title">{challenge.name}</div>
                        <div className="item_desc">{challenge.intro}</div>
                        <div className="more_btn">자세히 보기</div>
                    </div>
                </Link>
            ))}
        </div>
    );
};

export default ChallengeList;