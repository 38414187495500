import commonStyles from "../../common/css/Common.module.css";
import {MdBody, MdFooter, MdHeader, ModalBox} from "../common/ModalTags";
import challengeStyles from "../../common/css/Challenge.module.css";
import EnterItem from "../common/EnterItem";
import {useState} from "react";
import {isBlankStr, pwPattern} from "../../Utils/ValidateUtils";
import useKeepDoubleClick from "../../common/hook/useKeepDoubleClick";
import {useDialog} from "../../common/hook/DialogContext";
import {useNavigate} from "react-router-dom";
import Url from "../../common/Url";

const ResetPasswordMd = ({open, closedMd, setIsLogin}) => {
    const [npw, setNpw] = useState("");
    const [cpw, setCpw] = useState("");
    const [clicked, preventDoubleClick] = useKeepDoubleClick();
    const {addAlert, addConfirm} = useDialog();
    const navigate = useNavigate();

    const close = () => {
        setNpw("");
        setCpw("");
        closedMd();
    }

    const updateData = () => {
        fetch(`/v1/users/password`, {
            headers: {
                "Content-Type": "application/json",
            },
            method: "put",
            credentials: "include",
            redirect: "follow",
            body: JSON.stringify({
                npw: npw,
                cpw: cpw
            }),
        }).then((response) => {
            clicked.current = false;
            if (response.ok) {
                addAlert(
                    "수정 되었습니다.",
                    () => close()
                );
            } else if (response.status === 400) {
                response.json().then(json => {
                    addAlert(json.message);
                })
            } else if (response.status === 401) {
                response.json().then(json => {
                    setIsLogin(false);
                    addAlert(json.message, () => {
                        navigate(Url.login);
                    });
                })
            }else {
                throw new Error("수정할 수 없습니다. 다시 시도해주세요.");
            }
        }).catch(e => {
            addAlert(e.message);
        });
    };

    const validation = () => {
        if(!pwPattern.test(npw) || !npw) {
            addAlert("비밀번호를 영문, 숫자, 특수 문자를 사용하여\n8자 이상으로 입력해 주세요.");
            return false;
        }
        if(isBlankStr(cpw)) {
            addAlert("비밀번호 확인을 입력해주세요.");
            return false;
        }
        if (npw !== cpw) {
            addAlert("비밀번호가 일치하지 않습니다.");
            return false;
        }

        return true;
    };
    const submit = () => {
        preventDoubleClick(updateData, validation);
    };

    return (
        <ModalBox addClass={commonStyles.reset_password} open={open} close={close}>
            <div>
                <div>
                    <MdHeader>비밀번호 재설정</MdHeader>
                    <MdBody>
                        <p>* 비밀번호는 영문+숫자+특수문자 포함 8자리 이상</p>
                        <div className={`${commonStyles.enter_wrap} ${challengeStyles.enter_wrap}`}>
                            <EnterItem addClass={challengeStyles.enter_item}>
                                <label htmlFor="user_npw" className={commonStyles.sr_only}>새 비밀번호</label>
                                <div className={`${commonStyles.enter} ${challengeStyles.enter}`}>
                                    <input
                                        type="password"
                                        id="user_npw"
                                        placeholder="새 비밀번호 입력"
                                        value={npw}
                                        onChange={(e) => setNpw(e.target.value)}
                                    />
                                </div>
                            </EnterItem>
                            <EnterItem addClass={challengeStyles.enter_item}>
                                <label htmlFor="user_cpw" className="sr_only">새 비밀번호 확인</label>
                                <div className={`${commonStyles.enter} ${challengeStyles.enter}`}>
                                    <input
                                        type="password"
                                        id="user_cpw"
                                        placeholder="새 비밀번호 확인 입력"
                                        value={cpw}
                                        onChange={(e) => setCpw(e.target.value)}
                                    />
                                </div>
                            </EnterItem>
                        </div>
                    </MdBody>
                    <MdFooter>
                        <div className={`${commonStyles.bottom_btn_wrap} ${challengeStyles.bottom_btn_wrap}`}>
                            <button
                                type="button"
                                className={`${commonStyles.cc_btn} ${challengeStyles.cc_btn}`}
                                onClick={submit}
                            >
                                <span>확인</span>
                            </button>
                        </div>
                    </MdFooter>
                </div>
            </div>
        </ModalBox>
    );
};

export default ResetPasswordMd;