import challengeStyles from "../../common/css/Challenge.module.css";
import ChallengeTeamCreateAgreement from "./ChallengeTeamCreateAgreement";
import ChallengeTeamCreateApplication from "./ChallengeTeamCreateApplication";
import BbsWrap from "./BbsWrap";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../common/hook/AuthContext";
import {useDialog} from "../../common/hook/DialogContext";

const ChallengeTeamCreate = ({innerMenu, uid, challengeRefresh, setChallengeRefresh}) => {
    const { isLogin } = useAuth();
    const navigate = useNavigate();
    const {addAlert, addConfirm} = useDialog();

    useEffect(() => {
        if (!isLogin) {
            addAlert("로그인이 필요합니다.");
            navigate(`/challenge/${uid}/team`);
        }
    }, []);

    return (
        <BbsWrap>
            <div className={challengeStyles.top}>
                <div className={challengeStyles.table_title}>팀 만들기</div>
            </div>

            {isLogin && (
                <>
                    {innerMenu === "createAgreement" && (
                        <ChallengeTeamCreateAgreement uid={uid}/>
                    )}

                    {innerMenu === "createApplication" && (
                        <ChallengeTeamCreateApplication uid={uid} challengeRefresh={challengeRefresh} setChallengeRefresh={setChallengeRefresh}/>
                    )}
                </>
            )}
        </BbsWrap>
    );
};

export default ChallengeTeamCreate;