
const Footer = ({footerRef}) => {
    return (
        <footer ref={footerRef} id="footer">
            <div>
                <div className="copyright">© MAIC 2020. All rights reserved</div>
                <button type="button" className="email_refusal_btn"><span>이메일무단수집거부</span></button>
            </div>
        </footer>
    );
};

export default Footer;