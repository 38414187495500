import TabItem from "../challenge/TabItem";
import challengeStyles from "../../common/css/Challenge.module.css";
import commonStyles from "../../common/css/Common.module.css";
import SrOnly from "../common/SrOnly";
import MyPageInfo from "./MyPageInfo";
import MyPageChallenge from "./MyPageChallenge";
import Url from "../../common/Url";
import MyPageBoard from "./MyPageBoard";

const MyPage = ({ tabMenu }) => {

    return (
        <>
            <SrOnly tag={"h2"}>마이페이지 - 내 게시물 페이지</SrOnly>

            <div className={challengeStyles.mypage_wrap}>
                <div className={challengeStyles.title}>마이페이지</div>
                <div className={challengeStyles.cc_tabs}>
                    <TabItem isOn={tabMenu === "info"} url={Url.myPageInfo}>내 정보</TabItem>
                    <TabItem isOn={tabMenu === "challenge"} url={Url.myPageChallenge}>참여 대회</TabItem>
                    <TabItem isOn={tabMenu === "board"} url={Url.myPageBoard}>내 게시물</TabItem>
                </div>

                {tabMenu === "info" && (
                    <MyPageInfo />
                )}

                {tabMenu === "challenge" && (
                    <MyPageChallenge />
                )}

                {tabMenu === "board" && (
                    <MyPageBoard />
                )}

            </div>
        </>
    );
};

export default MyPage;