import {Link} from "react-router-dom";
import commonStyles from "../../../common/css/Common.module.css";
import challengeStyles from "../../../common/css/Challenge.module.css";
import {isEmptyObj} from "../../../Utils/CommonUtils";
import {useAuth} from "../../../common/hook/AuthContext";
import myPageBoardStyles from "../../myPage/MyPageBoard.module.css";

const BoardList = ({boardList, prevPage, isMyPage = false}) => {
    const { userUid } = useAuth();

    return (
        <div className={`${challengeStyles.bbs_table} ${isMyPage ? myPageBoardStyles.bbs_table : ""}`}>
            <table>
                <caption><strong>게시판 테이블 입니다.</strong>NO, 제목, 댓글, 작성자, 작성일이 명시되어 있습니다.</caption>
                <colgroup>
                    <col style={{ width: "80px" }}/>
                    <col/>
                    <col style={{ width: "80px" }}/>
                    <col style={{ width: "120px" }}/>
                    <col style={{ width: "120px" }}/>
                </colgroup>
                <thead>
                    <tr>
                        <th scope="col"><div>NO</div></th>
                        <th scope="col" className={challengeStyles.ta_left}><div>제 목</div></th>
                        <th scope="col"><div>댓글</div></th>
                        <th scope="col"><div>작성자</div></th>
                        <th scope="col"><div>작성일</div></th>
                    </tr>
                </thead>
                <tbody>
                    {isEmptyObj(boardList) ? (
                        <tr>
                            <td colSpan="5" className={commonStyles.no_con}>
                                <div>등록된 글이 없습니다.</div>
                            </td>
                        </tr>
                    ) : (
                        boardList.map((board, index) => (
                            <tr key={"board" + index}>
                                <td>
                                    <div>
                                        {!isMyPage && board.top ?
                                            <span className={challengeStyles.table_badge}>공지</span>
                                            :
                                            board.rowId
                                        }
                                    </div>
                                </td>
                                <td className={`${challengeStyles.ta_left} ${!board.secret ? '' : challengeStyles.lock}`}>
                                        <div>
                                    {!board.secret ? 
                                            <Link
                                                to={`/challenge/${board.challengeUid}/board/${board.uid}`}
                                                title="상세 페이지로 이동"
                                                state={{
                                                  prevPage: prevPage,
                                                }}
                                                >
                                                [{board.categoryName}] {board.title}
                                            </Link>
                                        :
                                        <>
                                            {(board.creatorUid === userUid ? 
                                            <Link
                                                to={`/challenge/${board.challengeUid}/board/${board.uid}`}
                                                title="상세 페이지로 이동"
                                                state={{
                                                    prevPage: prevPage,
                                                }}
                                                >
                                                [{board.categoryName}] {board.title}
                                            </Link>
                                            :
                                            "비밀글 입니다.")}
                                        </>
                                    }
                                    </div>
                                </td>
                                <td>
                                    <div>{board.commentsCnt}</div>
                                </td>
                                <td>
                                    <div>
                                        {(!board.secret || board.creatorUid === userUid) ? 
                                            board.creator
                                            :
                                            "비밀글"
                                        }
                                    {}
                                    </div>
                                </td>
                                <td>
                                    <div>{board.createdDate}</div>
                                </td>
                            </tr>
                        ))
                        )}
                </tbody>
            </table>
        </div>
    )
};

export default BoardList;